import React from 'react';

const ReviewStep = ({
  selectedBlock,
  researchObjective,
  conceptDescription,
  questions,
  audienceOption,
  numberOfParticipants,
  incentiveAmount,
  selectedCountry,
  selectedState,
  selectedEducation,
  selectedOccupation,
  selectedLanguage,
  selectedIncomeRange,
  selectedGender,
  minAge,
  maxAge,
  savedScreenerQuestions,
  externalProjectTitle,
  externalProjectDescription,
  hasNonEmptyCriteria,
  campaignName,
  setCampaignName
}) => {
  return (
    <div className="review-step">
    
        <div className="step-label">Project Name</div>
        <input
          type="text"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          placeholder="Give a name to your project"
          className="campaign-name-input-review"
        />

      
            <div className='step-label'>Review research design</div>
            <div className="review-section">

              <div className='review-design-headers'>Research Type</div>
              <div className="review-content">
                {selectedBlock === 1 && 'Consumer Research'}
                {selectedBlock === 2 && 'Ad Testing'}
                {selectedBlock === 3 && 'Concept Testing'}
                {selectedBlock === 4 && 'Consumer Feedback'}
              </div>
              <div className='review-design-headers'>Research Objective</div>
              <div className="review-content">{researchObjective}</div>
              <div className='review-design-headers'>Questions</div>
              <div className="review-content">
                {questions.map((q, index) => (
                  <div key={index}>
                    <p>{q.question}</p>
                    {q.followup && (
                      <em className="followup-text">
                        Potential Followup: {q.followup}
                      </em>
                    )}
                  </div>
                ))}
              </div>
              <div className='review-design-headers'>Target audience</div>
              <div className="review-content">
              {audienceOption === 'recruit' ? (
                <>
                  {/* Number of Participants and Incentive Amount */}
                  <p> Number of Participants: {numberOfParticipants}</p>
                  <p> Incentive Amount: {incentiveAmount}</p>

                  {selectedCountry && selectedCountry.length > 0 && (
                    <p>Country: {selectedCountry.map(c => c.label).join(', ')}</p>
                  )}
                  {selectedState && selectedState.length > 0 && (
                    <p>State: {selectedState.map(s => s.label).join(', ')}</p>
                  )}
                  {selectedEducation && selectedEducation.length > 0 && (
                    <p>Education: {selectedEducation.map(e => e.label).join(', ')}</p>
                  )}
                  {selectedOccupation && selectedOccupation.length > 0 && (
                    <p>Occupation: {selectedOccupation.map(o => o.label).join(', ')}</p>
                  )}
                  {selectedLanguage && selectedLanguage.length > 0 && (
                    <p>Language: {selectedLanguage.map(i => i.label).join(', ')}</p>
                  )}
                  {selectedIncomeRange && selectedIncomeRange.length > 0 && (
                    <p>Income Range: {selectedIncomeRange.map(i => i.label).join(', ')}</p>
                  )}
                  {selectedGender && <p>Gender: {selectedGender.label}</p>}
                  {(minAge || maxAge) && <p>Age: {minAge} - {maxAge}</p>}

                  {/* Screener Questions */}
                  <div className='review-design-headers'>Screener Questions</div>
                  <div >
                    {savedScreenerQuestions.length > 0 ? (
                      <ul className="screener-question-list">
                        {savedScreenerQuestions.map((question, index) => (
                          <li key={index}>{question.questionText}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No screener questions added.</p>
                    )}
                  </div>
                  <div className='review-design-headers'>External Project Name</div>
                  <div className="review-content">{externalProjectTitle}</div>
                  <div className='review-design-headers'>External Project Title</div>
                  <div className="review-content">{externalProjectDescription}</div>

                </>
              ) : (
                <p>Audience: Own users</p>
              )}

            </div>
    


            </div>
            {selectedBlock !== 1 && selectedBlock !== 4 && (
            <div className="review-section">
              <h2>Concept Description</h2>
              <div className="review-content">{conceptDescription}</div>
            </div>)}
            {/* <div className="review-section">
              <h2>Business Usage</h2>
              <div className="review-content">{businessUsage}</div>
            </div> */}

          </div>

  );
};

export default ReviewStep;
