import React from 'react';

const TestStep = ({ testLink }) => {
  return (
    <div className="test-step">
    <div className='step-label'> Review interview experience</div>
    <button
      className="test-interview-button"
      onClick={() => window.open(`https://feedback.echovane.com/interviews/${testLink}`, '_blank')}
    >
      Test Interview
    </button>
    <div className='test-interview-instruction'>
    Give the interview a try and make any tweaks to the questions or research objectives if you think something's missing. When you're ready, hit 'Submit' in the next step and we'll generate a Live link for you to share with your users!
    </div>
  </div>
  );
};

export default TestStep;
