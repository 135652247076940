import React, { useState} from 'react';

const WhoStep = ({
  audienceOption,
  setAudienceOption,
  numberOfParticipants,
  incentiveAmount,
  setShowRecruitmentSheet,
  setShowScreenerSheet,
  handleEditRecruitmentCriteria,
  handleEditScreenerQuestions,
  validationErrors,
  savedRecruitmentCriteria,
  savedScreenerQuestions,
  hasNonEmptyCriteria,
  externalProjectTitle,
  externalProjectDescription,
  handleParticipantsChange,
  handleIncentiveChange,
  handleExternalTitleChange,
  handleExternalDescriptionChange,
}) => {
  const [showPauseNotification, setShowPauseNotification] = useState(true);

  return (
    <div className="who-step">
      <div className='step-label'>Select target audience</div>
      <div className="audience-options">
        <label className="radio-container">
          <input
            type="radio"
            value="own"
            checked={audienceOption === 'own'}
            onChange={() => setAudienceOption('own')}
          />
          <span className="custom-radio-circle"></span>
          Target your own audience
        </label>

        <label className="radio-container">
          <input
            type="radio"
            value="recruit"
            checked={audienceOption === 'recruit'}
            onChange={() => setAudienceOption('recruit')}
          />
          <span className="custom-radio-circle"></span>
          Recruit audience
        </label>
      </div>

      <hr className="full-width-line" />

      {audienceOption === 'recruit' && (
        <>
        {showPauseNotification && (
              <div className="pause-notification">
                <p>Hi! Recruiting participants requires a balance for incentives. Your recruitment is paused until your account has sufficient funds. For help adding balance, please contact us at contact@echovane.com</p>
              </div>
            )}
          <div className="recruit-container-whole">

            <div className="recruit-container">
              <div className='recruit-input-sections'>

                <div className='input-section'>
                  <div className='internal-header'>Number of Participants</div>

                  <input
                    type="number"
                    className="input-box"
                    placeholder="Enter number of participants"
                    value={numberOfParticipants}
                    onChange={handleParticipantsChange}
                  />

                  {validationErrors.participants && (
                    <div className="err-message">{validationErrors.participants}</div>
                  )}
                </div>

                <div className="input-section">
                  <div className='internal-header'>Incentive Amount ($)</div>
                  <input
                    type="number"
                    className="input-box"
                    placeholder="Enter incentive amount"
                    value={incentiveAmount}
                    onChange={handleIncentiveChange}
                  />
                  {validationErrors.incentive && (
                    <div className="err-message">{validationErrors.incentive}</div>
                  )}
                </div>
              </div>

              <div className="recruit-left-section">
                <div className='internal-header'>Recruitment Criteria</div>

                {validationErrors.country && (
                  <div className="err-message">{validationErrors.country}</div>
                )}
                <p>
                  {savedRecruitmentCriteria && hasNonEmptyCriteria(savedRecruitmentCriteria)
                    ? ''
                    : 'Set the criteria for your audience, such as age, country, income, etc.'}
                </p>

                {savedRecruitmentCriteria && hasNonEmptyCriteria(savedRecruitmentCriteria) ? (
                  <div className="saved-criteria">
                    {savedRecruitmentCriteria.countries && savedRecruitmentCriteria.countries.length > 0 && (
                      <p><strong>Country:</strong> {savedRecruitmentCriteria.countries.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.state && savedRecruitmentCriteria.state.length > 0 && (
                      <p><strong>State:</strong> {savedRecruitmentCriteria.state.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.age && (savedRecruitmentCriteria.age.min || savedRecruitmentCriteria.age.max) && (
                      <p><strong>Age:</strong> {savedRecruitmentCriteria.age.min} - {savedRecruitmentCriteria.age.max}</p>
                    )}
                    {savedRecruitmentCriteria.education && savedRecruitmentCriteria.education.length > 0 && (
                      <p><strong>Education:</strong> {savedRecruitmentCriteria.education.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.occupation && savedRecruitmentCriteria.occupation.length > 0 && (
                      <p><strong>Occupation:</strong> {savedRecruitmentCriteria.occupation.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.incomeRange && savedRecruitmentCriteria.incomeRange.length > 0 && (
                      <p><strong>Income Range:</strong> {savedRecruitmentCriteria.incomeRange.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.gender && (
                      <p><strong>Gender:</strong> {savedRecruitmentCriteria.gender}</p>
                    )}
                    {savedRecruitmentCriteria.language && savedRecruitmentCriteria.language.length > 0 && (
                      <p><strong>Language:</strong> {savedRecruitmentCriteria.language.join(', ')}</p>
                    )}
                    <button className="edit-button" onClick={handleEditRecruitmentCriteria}>Edit</button>
                  </div>
                ) : (
                  <button className="add-button" onClick={() => setShowRecruitmentSheet(true)}>Add</button>
                )}

              </div>

              <div className="recruit-right-section">
                <div className='internal-header'>Screener Questions</div>

                {savedScreenerQuestions.length > 0 ? (
                  <div className="saved-screener-questions">
                    <ul className='screener-question-list'>
                      {savedScreenerQuestions.map((q, index) => (
                        <li key={index}>{q.questionText}</li>
                      ))}
                    </ul>
                    <button className="edit-button" onClick={handleEditScreenerQuestions}>Edit</button>
                  </div>
                ) : (
                  <>
                    <p>Ask questions to further filter your audience.</p>
                    <button className="add-button" onClick={() => setShowScreenerSheet(true)}>Add</button>
                  </>
                )}

                {validationErrors.screenerQuestions && (
                  <div className="err-message">{validationErrors.screenerQuestions}</div>
                )}
              </div>

            </div>
            <div className="vertical-line"></div>
            <div className='recruit-communication'>
              <div className='input-section'>
                <div className='internal-header'>External Project Title</div>

                <input
                  type="text"
                  className="input-box-title"
                  placeholder="Enter project title which recruits will see"
                  value={externalProjectTitle}
                  onChange={handleExternalTitleChange}
                />

                {validationErrors.externalProjectTitle && (
                  <div className="err-message">{validationErrors.externalProjectTitle}</div>
                )}
              </div>

              <div className="input-section">
                <div className='internal-header'>External Project Description</div>
                <textarea
                  className="input-box-description"
                  placeholder="Enter project description which recruits will see."
                  value={externalProjectDescription}
                  onChange={handleExternalDescriptionChange}
                />
                {validationErrors.externalProjectDescription && (
                  <div className="err-message">{validationErrors.externalProjectDescription}</div>
                )}
              </div>

            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WhoStep;
