// In ConfirmDialog.js
import React from 'react';

const ConfirmDialog = ({ isOpen, onClose, title, description, options }) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-dialog-modal-backdrop">
      <div className="confirm-dialog-modal-content">
        <h2>{title}</h2>
        <p className="confirm-dialog-description">{description}</p>
        <div className="confirm-dialog-buttons">
          {options.map((option, index) => (
            <button
              key={index}
              onClick={option.onClick}
              className={option.className}
              disabled={option.disabled}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
