import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';

const RecruitmentCriteriaBottomSheetStep = ({
  showRecruitmentSheet,
  setShowRecruitmentSheet,
  selectedCriteria,
  setSelectedCriteria,
  availableCriteria,
  setAvailableCriteria,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  selectedEducation,
  setSelectedEducation,
  selectedOccupation,
  setSelectedOccupation,
  selectedIncomeRange,
  setSelectedIncomeRange,
  selectedGender,
  setSelectedGender,
  selectedLanguage,
  setSelectedLanguage,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  handleCriteriaSelect,
  handleCriteriaDeselect,
  handleSaveRecruitmentCriteria,
  countryOptions,
  stateOptions,
  educationOptions,
  occupationOptions,
  incomeRangeOptions,
  genderOptions,
  languageOptions,
}) => {
  return (
    <div className={`bottom-sheet-recruit ${showRecruitmentSheet ? 'show' : ''}`}>
    {showRecruitmentSheet && (
      <>
      <div className = "bottom-sheet-header">
        <h3>Add Recruitment Criteria</h3>
        <div className="bottom-sheet-actions">
            <button className="button-container-back-button" onClick={() => setShowRecruitmentSheet(false)}>
              Cancel
            </button>
            <button className="continue-button" onClick={handleSaveRecruitmentCriteria}>
              Save
            </button>
        </div>
        </div>
        <hr className="screener-line" />
        <div className="bottom-sheet-body">
        <div className="recruitment-left">
          <h3>Available Criteria</h3>
          <hr className="filter-divider" />
          
          {availableCriteria.length === 0 ? (
          <p className="empty-message">All criteria have been selected.</p>
        ) : (
          <div className="criteria-list">
          {availableCriteria.map((option) => (
            <div key={option} className="criteria-chip" onClick={() => handleCriteriaSelect(option)}>
              {option} <span>+</span>
            </div>
          ))}
          </div>
        )}
        </div>
        <div className="recruitment-right">
          <h3>Selected Criteria</h3>
          <hr className="filter-divider" />
          {selectedCriteria.length === 0 ? (
            <p className="empty-message">No criteria selected yet.</p>
          ) : (
          <div className="filters-container">
          {selectedCriteria.includes('Country') && (
                <div className="filter">
                  <div className="filter-header">
                    <div>Country</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Country')}><DeleteIcon /></button>
                  </div>
                  
                  <Select
                    isMulti
                    options={countryOptions}
                    placeholder="Search Country"
                    classNamePrefix="custom-select"
                    value={selectedCountry}
                    onChange={setSelectedCountry}
                  />
                  {/* <hr className="filter-divider" /> */}
                </div>
              )}
            {selectedCriteria.includes('State') && (
              <div className="filter">
                  <div className="filter-header">
                    <div>State</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('State')}><DeleteIcon /></button>
                  </div>
                 
                <Select
                  isMulti
                  options={stateOptions}
                  placeholder="Search State"
                  classNamePrefix="custom-select"
                  value={selectedState}
                  onChange={setSelectedState}
                />
                 {/* <hr className="filter-divider" /> */}
              </div>
            )}
              {selectedCriteria.includes('Age') && (
                <div className="filter age-filter">
                  <div className="filter-header">
                    <div>Age</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Age')}><DeleteIcon /></button>
                  </div>
                  
                  <div className="age-input-group">
                    <input
                      type="number"
                      placeholder="Min Age"
                      value={minAge}
                      onChange={(e) => setMinAge(e.target.value)}
                      className="age-input"
                    />
                    <span className="age-separator">to</span>
                    <input
                      type="number"
                      placeholder="Max Age"
                      value={maxAge}
                      onChange={(e) => setMaxAge(e.target.value)}
                      className="age-input"
                    />
                  </div>
                  {/* <hr className="filter-divider" /> */}
                </div>
              )}


              {selectedCriteria.includes('Household Income') && (
                  <div className="filter">
                  <div className="filter-header">
                    <div>Household Income</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Household Income')}><DeleteIcon /></button>
                  </div>
                  
                    <Select
                      isMulti
                      options={incomeRangeOptions}
                      placeholder="Search Income Range"
                      classNamePrefix="custom-select"
                      value={selectedIncomeRange}
                      onChange={setSelectedIncomeRange}
                    />
                    {/* <hr className="filter-divider" /> */}
                  </div>

                )}

                {selectedCriteria.includes('Gender') && (
                  <div className="filter">
                  <div className="filter-header">
                    <div>Gender</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Gender')}><DeleteIcon /></button>
                  </div>
                  
                    <Select
                      options={genderOptions}
                      placeholder="Select Gender"
                      classNamePrefix="custom-select"
                      value={selectedGender}
                      onChange={setSelectedGender}
                    />
                    {/* <hr className="filter-divider" /> */}
                  </div>
                )}

                {selectedCriteria.includes('Language') && (
                  <div className="filter">
                  <div className="filter-header">
                    <div>Language</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Language')}><DeleteIcon /></button>
                  </div>
                 
                    <Select
                      isMulti
                      options={languageOptions}
                      placeholder="Search Language"
                      classNamePrefix="custom-select"
                      value={selectedLanguage}
                      onChange={setSelectedLanguage}
                    />
                     {/* <hr className="filter-divider" /> */}
                  </div>
                )}

            {selectedCriteria.includes('Education Level') && (
              <div className="filter">
                  <div className="filter-header">
                    <div>Education Level</div>
                    <button className="delete-button" onClick={() => handleCriteriaDeselect('Education Level')}><DeleteIcon /></button>
                  </div>
                  
                <Select
                  isMulti
                  options={educationOptions}
                  placeholder="Search Education Level"
                  classNamePrefix="custom-select"
                  value={selectedEducation}
                  onChange={setSelectedEducation}
                />
                {/* <hr className="filter-divider" /> */}
              </div>
            )}
            {/* Add other criteria as needed */}
          </div>
          )}
        </div>
        </div>

      </>
    )}
  </div>
  );
};

export default RecruitmentCriteriaBottomSheetStep;
