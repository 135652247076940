import React, { useState, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop, useDragLayer } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AffinityTheme from "./AffinityTheme";
import "./AffinityCanvas.css";
import { FaPlus, FaMousePointer, FaHandPaper, FaDownload, FaSearchPlus, FaSearchMinus} from "react-icons/fa"; 
import {MdDownload, Md1kPlus, MdAdd, MdMouse} from 'react-icons/md';
import AffinityQuote from "./AffinityQuote";


import html2canvas from "html2canvas";

const AffinityCanvas = ({ initialWidth = "80vw", initialHeight = "800px" , codes}) => {
  const canvasContentRef = useRef(null);
  const [quotes, setQuotes] = useState([]);
  
  const [themes, setThemes] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const canvasScrollRef = useRef(null);
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);
  const canvasRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const quoteIdCounter = useRef(0);
  const [activeTool, setActiveTool] = useState("select");

  const [clipboard, setClipboard] = useState([]);


  let startX = 1200;
  let startY = 2000;
  let quoteSpacing = 100; 

  const [{ isOverCanvas, canDropCanvas }, drop] = useDrop(() => ({
    accept: "QUOTE",
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      return { themeId: null };
    },
    collect: (monitor) => ({
      isOverCanvas: monitor.isOver({ shallow: true }),
      canDropCanvas: !!monitor.canDrop(),
    }),
  }));
  

const moveTheme = (id, left, top) => {
  setThemes((prevThemes) =>
    prevThemes.map((theme) =>
      theme.id === id ? { ...theme, left, top } : theme
    )
  );
};



const addTheme = () => {
  const themesPerRow = 4;
  const themeWidth = 400;
  const themeHeight = 400;
  const index = themes.length;

  const newTheme = {
    id: themes.length + 1,
    title: "New Theme",
    left: 1000 + (index % themesPerRow) * themeWidth,
    top: 2500 + Math.floor(index / themesPerRow) * themeHeight,
  };

  setThemes([...themes, newTheme]);
};



  // Remove theme
  const removeTheme = (id) => setThemes(themes.filter((theme) => theme.id !== id));

  // Update theme title
  const updateThemeTitle = (id, title) => {
    setThemes(themes.map((theme) => (theme.id === id ? { ...theme, title } : theme)));
  };


// In AffinityCanvas.js
const addQuote = () => {
 
  const newQuote = {
    id: quoteIdCounter.current++,
    content: "New Quote",
    themeId: null,
    order: 0,
  };
  setQuotes([...quotes, newQuote]);

  // Focus the view on the unthemed quotes area
  if (canvasScrollRef.current && canvasContentRef.current) {
    const canvasScroll = canvasScrollRef.current;
    const zoomAdjustedStartX = startX * zoomLevel;
    const zoomAdjustedStartY = startY * zoomLevel;

    canvasScroll.scrollTo({
      left: zoomAdjustedStartX - canvasScroll.clientWidth / 2,
      top: zoomAdjustedStartY - canvasScroll.clientHeight / 2,
      behavior: 'smooth',
    });
  }
};

  
  const handleZoomIn = () => setZoomLevel(prev => Math.min(prev + 0.1, 2)); // Max zoom 2x
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.5)); // Min zoom 0.5x


  const handleDownload = () => {
    html2canvas(canvasRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.download = "affinity-map.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

// AffinityCanvas.js

useEffect(() => {
  if (codes && codes.codes) {
    const themesPerRow = 4;
    const themeWidth = 400;  
    const themeHeight = 600; 

    const newThemes = codes.codes.map((codeItem, index) => ({
      id: index + 1,
      title: codeItem.code,
      left: 2200 + (index % themesPerRow) * themeWidth,
      top: 2500 + Math.floor(index / themesPerRow) * themeHeight,
    }));

    let quoteCounter = 1; 
    const newQuotes = [];
    codes.codes.forEach((codeItem, themeIndex) => {
      codeItem.representative_quotes.forEach((quoteContent, quoteIndex) => {
        newQuotes.push({
          id: quoteCounter++,
          content: quoteContent,
          themeId: themeIndex + 1,
          columnIndex: 0,
          order: quoteIndex, 
        });
      });
    });

    quoteIdCounter.current = quoteCounter;

    setThemes(newThemes);
    setQuotes(newQuotes);
   
  }
}, [codes]);

  

  useEffect(() => {
    if (canvasScrollRef.current && canvasContentRef.current) {
      const canvasScroll = canvasScrollRef.current;
      const canvasContent = canvasContentRef.current;
  
      const contentWidth = canvasContent.offsetWidth * zoomLevel;
      const contentHeight = canvasContent.offsetHeight * zoomLevel;
  
      const scrollLeft = (contentWidth - canvasScroll.clientWidth) / 2 / zoomLevel;
      const scrollTop = (contentHeight - canvasScroll.clientHeight) / 2 / zoomLevel;
  
      canvasScroll.scrollLeft = scrollLeft;
      canvasScroll.scrollTop = scrollTop;
    }
  }, []);

  const updateThemePosition = (id, left, top) => {
    setThemes((prevThemes) =>
      prevThemes.map((theme) =>
        theme.id === id ? { ...theme, left, top } : theme
      )
    );
  };

  const updateQuotePosition = (id, left, top) => {
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === id ? { ...quote, left, top } : quote
      )
    );
  };

  const updateQuoteTheme = (quoteId, themeId, columnIndex) => {
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === quoteId
          ? { ...quote, themeId, columnIndex: columnIndex ?? quote.columnIndex }
          : quote
      )
    );
  };


useEffect(() => {
  if (canvasScrollRef.current && canvasContentRef.current) {
    const canvasScroll = canvasScrollRef.current;
    const canvasContent = canvasContentRef.current;

    const contentWidth = canvasContent.offsetWidth * zoomLevel;
    const contentHeight = canvasContent.offsetHeight * zoomLevel;

    const scrollLeft = (contentWidth - canvasScroll.clientWidth) / 2;
    const scrollTop = (contentHeight - canvasScroll.clientHeight) / 2;

    canvasScroll.scrollLeft = scrollLeft;
    canvasScroll.scrollTop = scrollTop;
  }
}, [zoomLevel]);

  

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`affinity-canvas ${isFullScreen ? "full-screen" : ""}` }
        ref={canvasRef}
        style={{
          width: isFullScreen ? "100vw" : initialWidth,
          height: isFullScreen ? "100vh" : initialHeight,
        }}
      >
        <div className="top-bar">
          <div className = "download-icon" onClick={handleDownload}><FaDownload/></div>
        </div>
          <div className="floating-action-bar">
          <button
            className={activeTool === "add" ? "active" : ""}
            onClick={() => {
              setActiveTool("add");
              addQuote();
            }}
          >
            <FaPlus />
          </button>
          <button
            className={activeTool === "select" ? "active" : ""}
          >
            <FaMousePointer />
          </button>
          {/* <button
            className={activeTool === "grab" ? "active" : ""}
            onClick={() => setActiveTool("grab")}
          >
            <FaHandPaper />
          </button> */}
        </div>
        <div className="canvas-scroll-container" ref={canvasScrollRef}>
        <div className="canvas-content" 
        ref={(node) => {
          canvasContentRef.current = node;
          drop(node);
        }}
        style={{
          transform: `scale(${zoomLevel})`,
          backgroundColor: isOverCanvas && canDropCanvas ? "#e0e0e0" : "transparent",
        }} >
        
        <div className="themes-container">
        {themes.map((theme) => (
          <AffinityTheme
            key={theme.id}
            theme={theme}
            quotes={quotes.filter((quote) => quote.themeId === theme.id)}
            updateThemeTitle={updateThemeTitle}
            removeTheme={removeTheme}
            updateThemePosition={updateThemePosition}
            moveTheme={moveTheme}
            zoomLevel={zoomLevel}
            canvasContentRef={canvasContentRef}
            setQuotes={setQuotes}
            updateQuotePosition={updateQuotePosition}
            updateQuoteTheme={updateQuoteTheme}   
            activeTool={activeTool}   
          />
          ))}
        </div>

        {quotes
        .filter((quote) => quote.themeId === null)
        .map((quote, index) => {
          const position = {
            left: startX,
            top: startY + index * quoteSpacing,
          };
         
          return (
            

            <AffinityQuote
              key={quote.id}
              quote={{ ...quote, left: position.left, top: position.top }}
              updateQuotePosition={updateQuotePosition}
              updateQuoteTheme={updateQuoteTheme}
              zoomLevel={zoomLevel}
              canvasContentRef={canvasContentRef}
              activeTool={activeTool}
            />

          );
        })}
        </div>
        </div>

        <div className="bottom-bar-actions">
        <button className="zoom-button" onClick={handleZoomIn}>
          <FaSearchPlus />
        </button>
        <button className="zoom-button" onClick={handleZoomOut}>
          <FaSearchMinus />
        </button>

        <button className="full-screen-toggle" onClick={toggleFullScreen}>
          {isFullScreen ? "Exit Full Screen" : "Full Screen"}
        </button>
        
        </div>



      </div>
    </DndProvider>
  );
};

export default AffinityCanvas;
