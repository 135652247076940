import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { FaGripVertical, FaTrashAlt } from 'react-icons/fa';

const ScreenerQuestionsBottomSheetStep = ({
  showScreenerSheet,
  setShowScreenerSheet,
  screenerQuestions,
  setScreenerQuestions,
  handleSaveScreenerQuestions,
  addNewQuestion,
  handleQuestionTextChange,
  handleQuestionTypeChange,
  handleDeleteScreenerQuestion,
  handleAddOption,
  handleRemoveOption,
  handleOptionChange,
  handleOptionActionChange,
  handleAnswerTextChange,
  handleToggleRequired,
  questionTypeOptions,
  multipleChoiceActionOptions,
  singleChoiceActionOptions,
  handleScreenerDragEnd,
  handleScreenerQuestionUpdate
}) => {
  return (
    <div className={`bottom-sheet-screener ${showScreenerSheet ? 'show' : ''}`}>
    {showScreenerSheet && (
      <>
          <div className="bottom-sheet-header">
            <h3>Add Screener Questions</h3>
            <div className="bottom-sheet-actions">
              <button className="button-container-back-button" onClick={() => setShowScreenerSheet(false)}>Cancel</button>
              <button className="continue-button" onClick={handleSaveScreenerQuestions}>Save</button>
            </div>
          </div>
          <hr className="screener-line" />
        <DragDropContext onDragEnd={handleScreenerDragEnd}>
          <Droppable droppableId="screenerQuestions">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
        {screenerQuestions.map((q, index) => (
            <Draggable key={q.id} draggableId={q.id} index={index}>
              {(provided) => (
          <div key={q.id} className="screener-question"        
           ref={provided.innerRef}
          {...provided.draggableProps}>
                    <div className="drag-handle" {...provided.dragHandleProps}>
                    <FaGripVertical />
                    </div>
            <div className="question-row">

            <input
              type="text"
              value={q.questionText}
              onChange={(e) => handleQuestionTextChange(q.id, e.target.value)}
              placeholder={`Enter question ${index + 1}`}
            />
                <Select
                  value={questionTypeOptions.find(opt => opt.value === q.questionType)}
                  onChange={(selectedOption) => handleQuestionTypeChange(q.id, selectedOption.value)}
                  options={questionTypeOptions}
                  className="question-type-select"
                />  

              <button
              className="delete-button-screener-question"
              onClick={() => handleDeleteScreenerQuestion(q.id)}
            >
              <FaTrashAlt />
            </button>
            </div>
            {q.questionType === 'single' || q.questionType === 'multiple' ? (
              <div className="options-container">
    {[
      ...q.options,
      ...Array(Math.max(0, (q.questionType === 'multiple' ? 2 : 2) - q.options.length)).fill({ text: '', action: '' })
    ].map((option, optionIndex) => (
      <div key={optionIndex} className="option-input">
        <input
          type={q.questionType === 'multiple' ? 'checkbox' : 'radio'}
          disabled
        />
        <input
          type="text"
          value={option.text}
          onChange={(e) => handleOptionChange(q.id, optionIndex, e.target.value)}
          placeholder={`Option ${optionIndex + 1}`}
        />
        <Select
          value={q.questionType === 'multiple' 
            ? multipleChoiceActionOptions.find(opt => opt.value === option.action) 
            : singleChoiceActionOptions.find(opt => opt.value === option.action)
          }
          onChange={(selectedOption) => handleOptionActionChange(q.id, optionIndex, selectedOption.value)}
          options={q.questionType === 'multiple' ? multipleChoiceActionOptions : singleChoiceActionOptions}
          className="option-action-select"
        />
        <button
          className="add-option-button"
          onClick={() => handleAddOption(q.id, optionIndex)}
        >
          +
        </button>
        {optionIndex >= 2 && (
          <button
            className="remove-option-button"
            onClick={() => handleRemoveOption(q.id, optionIndex)}
            disabled={q.questionType === 'multiple' ? q.options.length <= 1 : q.options.length <= 1} 
          >
            -
          </button>
        )}
      </div>
    ))}


                  </div>

            ) : q.questionType === 'short' ? (
              <input
                type="text"
                placeholder="Short Answer"
                value={q.answerText || ''} 
                onChange={(e) => handleAnswerTextChange(q.id, e.target.value)} // Track changes
                className="short-answer-placeholder"
              />
            )  : (
              <textarea
                placeholder="Long Answer"
                value={q.answerText || ''} 
                onChange={(e) => handleAnswerTextChange(q.id, e.target.value)} // Track changes
                className="long-answer-placeholder"
              />
            )
            
            }
            <label className="required-label">
              <input
                type="checkbox"
                checked={q.required}
                onChange={() => handleToggleRequired(q.id)}
              />
              Required
            </label>
          </div>
              )}
              </Draggable>
            
          
        ))}
        {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>

        
        <button className="add-question-button-screener" onClick={() => addNewQuestion('multiple')}>
          Add Question
        </button>


        
        </>
      )}
    </div>

  );
};

export default ScreenerQuestionsBottomSheetStep;
