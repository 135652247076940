import React, { useState, useEffect, useRef } from "react";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import { getEmptyImage } from 'react-dnd-html5-backend';

const AffinityQuote = ({
  quote,
  index, 
  moveQuote,
  themeId,
  columnIndex,
  updateQuotePosition,
  updateQuoteTheme,
  zoomLevel,
  canvasContentRef,
  isInTheme = false, 
  setQuoteDimensionsRef,
  activeTool,
  setQuoteHeight
}) => {
  
  const quoteRef = useRef(null);
  const initialOffsetRef = useRef({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({
    left: quote.left,
    top: quote.top,
  });


  const [, drop] = useDrop({
    accept: 'QUOTE',
    hover(item, monitor) {
      if (!quoteRef.current) return;

      const dragId = item.id;
      const hoverId = quote.id;

      // Only reorder if dragging within the same theme and column
      if (
        item.originalThemeId === themeId &&
        item.originalColumnIndex === columnIndex &&
        dragId !== hoverId
      ) {
        const hoverBoundingRect = quoteRef.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only move when the mouse has crossed half of the item's height
        if (item.index < index && hoverClientY < hoverMiddleY) return;
        if (item.index > index && hoverClientY > hoverMiddleY) return;

        // Move the quote
        moveQuote(item.index, index, columnIndex);

        // Update the index for the dragged item
        item.index = index;
      }
    },
    drop(item, monitor) {
      // Return a dropResult indicating that the quote is being dropped onto another quote in the same theme and column
      return { themeId: themeId, columnIndex: columnIndex };
    },
  });

  useEffect(() => {
    // Only set the reference if it hasn't been set before
    if (quoteRef.current && setQuoteDimensionsRef) {
      setQuoteDimensionsRef(quote.id, quoteRef);
    }
  }, []); // Remove `quoteRef` from dependencies to avoid infinite loop

  useEffect(() => {
    if (quoteRef.current && setQuoteHeight) {
      // Get the height of the quote element and pass it to setQuoteHeight
      const quoteHeight = quoteRef.current.getBoundingClientRect().height;
      setQuoteHeight(quoteHeight);
    }
  }, [quote, setQuoteHeight]); 
  

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "QUOTE",
    item: (monitor) => {
      if (quoteRef.current && monitor.getClientOffset()) {
        const quoteRect = quoteRef.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();
        const dropResult = monitor.getDropResult();
        

        initialOffsetRef.current = {
          x: clientOffset.x - quoteRect.left,
          y: clientOffset.y - quoteRect.top,
        };
      }
      return { id: quote.id, index: index,  originalIndex: index, originalThemeId: themeId, originalColumnIndex: columnIndex, left: quote.left || 0, top: quote.top || 0 , type: "QUOTE" , initialOffsetRef:  initialOffsetRef.current};
    },

end: (item, monitor) => {
  const dropResult = monitor.getDropResult();
  if (dropResult) {
    if (dropResult.themeId != null) {
     
      // Dropped onto a theme or column
      if (dropResult.columnIndex != null && ((item.originalThemeId !== dropResult.themeId || item.originalColumnIndex !== dropResult.columnIndex))) {
        // Dropped onto a specific column
        
        updateQuoteTheme(item.id, dropResult.themeId, dropResult.columnIndex);
      } else if (dropResult.handleDrop) {
        // Handle drop onto edge for resizing
        dropResult.handleDrop(item.id, quoteRef.current.offsetWidth, quoteRef.current.offsetHeight);
      } else {
        // Default behavior: assign to theme without changing columnIndex
        updateQuoteTheme(item.id, dropResult.themeId);
      }
      // Clear absolute positioning since it's now relative to the theme
      updateQuotePosition(item.id, undefined, undefined);
    } else {
      // Dropped onto canvas
      const clientOffset = monitor.getClientOffset();
      if (clientOffset && canvasContentRef.current) {
        const canvasRect = canvasContentRef.current.getBoundingClientRect();
        const left =
          (clientOffset.x - canvasRect.left - initialOffsetRef.current.x) /
          zoomLevel;
        const top =
          (clientOffset.y - canvasRect.top - initialOffsetRef.current.y) /
          zoomLevel;
        updateQuotePosition(item.id, left, top);
      }
      // Remove from any theme
      updateQuoteTheme(item.id, null);
    }
  }
},


    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    preview(); // Remove getEmptyImage() to keep the element visible
}, [preview]);


  const { isDragging: isCurrentlyDragging, item, clientOffset, itemType, dropResult } = useDragLayer(
    (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      itemType: monitor.getItemType(),
      dropResult: monitor.getDropResult(), 
    })
  );

  useEffect(() => {
    if (isCurrentlyDragging && itemType === "QUOTE" && item.id === quote.id) {
      // console.log("Live drop result:", dropResult); // Log dropResult live
    }
  }, [isCurrentlyDragging, dropResult, item, itemType, currentPosition]);

  useEffect(() => {
    if (
      !isInTheme &&
      isCurrentlyDragging &&
      clientOffset &&
      canvasContentRef.current &&
      item &&
      itemType === "QUOTE" && 
      item.id === quote.id
    ) {
      const canvasRect = canvasContentRef.current.getBoundingClientRect();
      const left =
        (clientOffset.x - canvasRect.left - initialOffsetRef.current.x) /
        zoomLevel;
      const top =
        (clientOffset.y - canvasRect.top - initialOffsetRef.current.y) /
        zoomLevel;

      // console.log("Live drag position:", { left, top }); 
      setCurrentPosition({ left, top });
    }
  }, [clientOffset, isCurrentlyDragging, item]);

  useEffect(() => {
    if (!isCurrentlyDragging) {
      setCurrentPosition({ left: quote.left, top: quote.top });
    }
  }, [quote.left, quote.top, isCurrentlyDragging]);

  drag(drop(quoteRef));

  return (
    <div
      ref={quoteRef}
      className={`quote ${isDragging ? "dragging" : ""}`}
      style={{
        position: quote.themeId != null ? "relative" : "absolute",
        left:quote.themeId != null ? undefined : currentPosition.left,
        top: quote.themeId != null ? undefined : currentPosition.top,
        pointerEvents: isDragging ? 'none' : 'auto',
      }}
      
    >
      <div>{quote.content}</div>
    </div>
  );
};

export default AffinityQuote;
