// Column.js

import React from 'react';
import { useDrop } from 'react-dnd';
import AffinityQuote from './AffinityQuote';

const Column = ({
  colIndex,
  quotes,
  themeId,
  updateQuotePosition,
  updateQuoteTheme,
  zoomLevel,
  canvasContentRef,
  isInTheme,
  handleSetQuoteDimensionsRef,
  activeTool,
  updateQuoteColumnIndex
}) => {

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "QUOTE",
    drop: (item, monitor) => {
    
      if (monitor.didDrop()) return; // Prevent double processing
      return { themeId: themeId, columnIndex: colIndex };
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop({ shallow: true }),
    }),
  }));
  
  

  const sortedQuotes = quotes.sort((a, b) => a.order - b.order);

  return (
    <div
      className="quote-column"
      style={{ width: '300px' }}
      ref={drop}
    >
      {sortedQuotes.map((quote) => (
        <AffinityQuote
          key={quote.id}
          quote={quote}
          updateQuotePosition={updateQuotePosition}
          updateQuoteTheme={updateQuoteTheme}
          zoomLevel={zoomLevel}
          canvasContentRef={canvasContentRef}
          isInTheme={isInTheme}
          setQuoteDimensionsRef={handleSetQuoteDimensionsRef}  
          activeTool={activeTool}   
        />
      ))}
    </div>
  );
};

export default Column;
