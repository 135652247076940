import React from 'react';
import consumerResearchImg from '../../assets/consumer_research.svg';
import conceptTestingImg from '../../assets/concept_testing.svg';
import consumerFeedbackImg from '../../assets/consumer_feedback.svg';

const ResearchTypeStep = ({ selectedBlock, onSelectBlock }) => {
  const options = [
    {
      id: 1,
      title: 'Consumer Research',
      description: 'Understand preferences and behaviors of your target consumers',
      img: consumerResearchImg,
    },
    // {
    //   id: 3,
    //   title: 'Concept Testing',
    //   description: 'Test new product ideas and features',
    //   img: conceptTestingImg,
    // },
    {
      id: 4,
      title: 'User Feedback',
      description: 'Gather feedback on your products or services directly from your customers',
      img: consumerFeedbackImg,
    },
  ];

  return (
    <div className="research-type-step">
      <div className="step-label">Choose type of research</div>
      <div className="research-grid">
        <div className="research-options">
          {options.map((block) => (
            <div
              className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
              key={block.id}
              onClick={() => onSelectBlock(block.id)}
            >
              <img src={block.img} alt={block.title} className="research-image" />
              <div className="block-text">
                <h2>{block.title}</h2>
                <p>{block.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResearchTypeStep;

