import React from 'react';

const ObjectiveStep = ({ researchObjective, setResearchObjective, validationError }) => {
  return (
    <div className="objective-step">
      <div className="question-block">
        <div className="step-label">Add research objective</div>
        <p className="example-text">
          Example: "To evaluate the effectiveness and user acceptance of a new AI-powered personal finance app among millennials (ages 25-40) in urban areas, with the goal of improving financial literacy and savings habits. This research aims to understand users' current financial management practices, assess the app's key features such as automated budgeting and investment recommendations, identify potential barriers to adoption, and gauge willingness to pay for premium features. The insights gathered will inform product development, marketing strategies, and potential partnerships with financial institutions, ultimately guiding the app's launch and growth strategy in the competitive fintech market."
        </p>
        <textarea
          id="researchObjective"
          value={researchObjective}
          onChange={(e) => setResearchObjective(e.target.value)}
        />
        {validationError && (
          <div className="err-message">{validationError}</div>
        )}
      </div>
    </div>
  );
};

export default ObjectiveStep;
