import React, { useState, useEffect, useRef , useCallback, useMemo} from "react";
import { useDrag, useDragLayer, useDrop,  } from "react-dnd";
import AffinityQuote from "./AffinityQuote";
import { getEmptyImage } from 'react-dnd-html5-backend';
import 'react-resizable/css/styles.css';
import { shadeColor } from "./utils";
import { Resizable } from 'react-resizable';
import Column from './Column'; 

const themeColors = [
  "#6B53F9",
  "#FD6851",
  "#379E6C",
  "#F6B242",
  "#A84F63",
  "#68B7EF",
  "#FEA773",
  "#137494",
  "#379E6C",
  "#FDB2AA",
  "#C277D3",
  "#53AEA6",
];



const AffinityTheme = ({ 
  theme,
  quotes,
  updateThemeTitle,
  removeTheme,
  updateThemePosition,
  moveTheme,
  zoomLevel,
  canvasContentRef,
  setQuotes,
  updateQuotePosition,
  updateQuoteTheme,
  activeTool
}) => {

  const [themeColor, setThemeColor] = useState("#ffffff");
  const [isEditing, setIsEditing] = useState(false);
  const themeRef = useRef(null);
  const initialOffsetRef = useRef({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ left: theme.left, top: theme.top });
  const [width, setWidth] = useState(250);  // Initial width
  const [height, setHeight] = useState(500); // Initial height
  const [highlightEdge, setHighlightEdge] = useState(null); // null, 'right', or 'bottom'
  const edgeThreshold = 20; 
  const quoteDimensionsRef = useRef({}); 
  const [isResizing, setIsResizing] = useState(false);
  const highlightRef = useRef(null);
  const quoteWidth = 300;
  const quotesPerRow = useMemo(() => {
    return Math.max(1, Math.floor((width) / quoteWidth));
  }, [width]);
  const getColorForThemeId = (id) => themeColors[id % themeColors.length];

  const isResizingRef = useRef(false);

  const setIsResizingState = (value) => {
    setIsResizing(value);
    isResizingRef.current = value;
  };

  const headerRef = useRef(null);


  const updateThemeHeight = useCallback((columnIndex) => {
    const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;

    // Calculate each column's height
    const columnHeights = Object.entries(quoteDimensionsRef.current).map(([colIndex, quotesInColumn]) => {
        const totalHeight = Object.values(quotesInColumn).reduce((sum, quoteHeight) => sum + quoteHeight + 20, 0);
        return totalHeight;
    });

    const maxColumnHeight = Math.max(...columnHeights, 0);
    const newHeight = headerHeight + maxColumnHeight;

    // Only set height if there is an actual difference
    if (newHeight !== height) {
        setHeight(newHeight);
    }
}, [height]); // Only re-run this calculation when `height` changes


  


  
const handleSetQuoteDimensions = useCallback((quoteId, columnIndex, height) => {
  if (!quoteDimensionsRef.current[columnIndex]) {
    quoteDimensionsRef.current[columnIndex] = [];
  }
  quoteDimensionsRef.current[columnIndex][quoteId] = height;
  updateThemeHeight();
}, []);

  

  useEffect(() => {
    setThemeColor(getColorForThemeId(theme.id));
  }, []);
  const backgroundColor = shadeColor(themeColor, 0.2); // Lighter background
  const borderColor = shadeColor(themeColor, 0); // Darker border

  
  const {
    isDragging: isCurrentlyDragging,
    item,
    clientOffset,
    itemType
  } = useDragLayer((monitor) => ({
    isDragging: !isResizingRef.current && monitor.isDragging(),
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    itemType: monitor.getItemType(),
  }));
  

  const handleTitleChange = (e) => updateThemeTitle(theme.id, e.target.value);

  const zoomLevelRef = useRef(zoomLevel);
  useEffect(() => {
    zoomLevelRef.current = zoomLevel;
  }, [zoomLevel]);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "THEME",
    item: (monitor) => {
      if (isResizingRef.current) return null; // Do not start drag if resizing
  
      if (themeRef.current && monitor.getClientOffset()) {
       
        const themeRect = themeRef.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();
  
        initialOffsetRef.current = {
          x: clientOffset.x - themeRect.left,
          y: clientOffset.y - themeRect.top,
        };
      }
  
    
      return { id: theme.id, left: theme.left || 0, top: theme.top || 0, type: "THEME" };
    },
    end: (item, monitor) => {
      if (isResizingRef.current || !item) return; // Ignore end if resizing or no item
  
      const clientOffset = monitor.getClientOffset();
      if (clientOffset && canvasContentRef.current && item.type === "THEME") {
        const canvasRect = canvasContentRef.current.getBoundingClientRect();
        const left = (clientOffset.x - canvasRect.left - initialOffsetRef.current.x) / zoomLevelRef.current;
        const top = (clientOffset.y - canvasRect.top - initialOffsetRef.current.y) / zoomLevelRef.current;
  
        
        moveTheme(item.id, left, top);
      }
    },
    collect: (monitor) => ({
      isDragging: !isResizingRef.current && monitor.isDragging(),
    }),
  }));

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "QUOTE",
    drop: (item, monitor) => {
     
      if (monitor.didDrop()) return;
      

      const clientOffset = monitor.getClientOffset();
      if (!clientOffset || !canvasContentRef.current) return { themeId: theme.id };

     
      if (highlightRef.current != null) {
        const columnIndex = null
        return { themeId: theme.id, handleDrop, columnIndex: columnIndex };
      }

      else {
      const canvasRect = canvasContentRef.current.getBoundingClientRect();
      const themeRect = themeRef.current.getBoundingClientRect();

      // Calculate final position within the theme by adjusting for offsets
      const finalLeft =
        (clientOffset.x - canvasRect.left - themeRect.left - item.initialOffsetRef.x) / zoomLevel;
      const finalTop =
        (clientOffset.y - canvasRect.top - themeRect.top - item.initialOffsetRef.y) / zoomLevel;

      // Calculate column index based on the adjusted x-coordinate
      const adjustedX =
        (clientOffset.x - themeRect.left - item.initialOffsetRef.x) / zoomLevel;
      const columnIndex = Math.max(0, Math.floor(adjustedX / 200));

      return { themeId: theme.id, handleDrop, columnIndex: columnIndex };

      
      }

      
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop({ shallow: true }),
    }),
  }));
  
  

  const handleSetQuoteDimensionsRef = useCallback((quoteId, ref) => {
    if (quoteDimensionsRef.current[quoteId] !== ref) {
      quoteDimensionsRef.current[quoteId] = ref;
     
    }
  }, []);
  

  const handleDrop = (quoteId, quoteWidth, quoteHeight) => {
   
  
    if (highlightRef.current === "right") {
      setWidth((prevWidth) => {
        const updatedWidth = prevWidth + quoteWidth + 0.1 * quoteWidth;
        const newColumnIndex = Math.floor(updatedWidth / 200);

        // Call updateQuoteTheme with the new column index here
        updateQuoteTheme(quoteId, theme.id, newColumnIndex);
  
        return updatedWidth;
      });
    } else if (highlightRef.current === "bottom") {
   
      setHeight((prevHeight) => {
        const newHeight = prevHeight + quoteHeight;
       
        return newHeight;
      });
    } else {
      updateQuoteTheme(quoteId, theme.id, undefined); // Keep in the existing column
    }
  
    setHighlightEdge(null);
  };
  
  const moveQuote = (dragIndex, hoverIndex, colIndex) => {
    setQuotes((prevQuotes) => {
      // Get quotes in the current column and theme
      const columnQuotes = prevQuotes
        .filter(
          (quote) =>
            quote.themeId === theme.id && quote.columnIndex === colIndex
        )
        .sort((a, b) => a.order - b.order || 0);
  
      // If quotes don't have an 'order' property, assign it
      columnQuotes.forEach((quote, idx) => {
        if (quote.order == null) quote.order = idx;
      });
  
      // Reorder the quotes
      const [removed] = columnQuotes.splice(dragIndex, 1);
      columnQuotes.splice(hoverIndex, 0, removed);
  
      // Update the order property
      columnQuotes.forEach((quote, idx) => {
        quote.order = idx;
      });
  
      // Merge back with other quotes
      return prevQuotes.map((quote) => {
        if (quote.themeId === theme.id && quote.columnIndex === colIndex) {
          return columnQuotes.find((q) => q.id === quote.id) || quote;
        }
        return quote;
      });
    });
  };
  
  
  
  const columns = [];
for (let i = 0; i < quotesPerRow; i++) {
  columns.push([]);
}

quotes.forEach((quote, index) => {
  const columnIndex = index % quotesPerRow;
  columns[columnIndex].push(quote);
});

  
  

  const { isDragging: isQuoteDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging() && !isResizingRef.current,
    itemType: monitor.getItemType(),
  }));

  useEffect(() => {
    highlightRef.current = highlightEdge;
  }, [highlightEdge]);
  
  
  useEffect(() => {
    if (isCurrentlyDragging && itemType === "QUOTE" && item && item.id) {
      const themeRect = themeRef.current.getBoundingClientRect();
  
      // Check if dragged quote is near the right or bottom edge for highlighting
      // console.log("checking where to highlight");
      if (clientOffset.x > themeRect.right - edgeThreshold) {
        // console.log("setting highlight to right");
        setHighlightEdge("right");
      } else if (clientOffset.y > themeRect.bottom - edgeThreshold) {
        // console.log("setting highlight to bottom");
        setHighlightEdge("bottom");
      } else {
        setHighlightEdge(null);
      }
    } else {
      setHighlightEdge(null);
    }
  }, [clientOffset, isCurrentlyDragging, item, itemType]);
  

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, [preview]);



  useEffect(() => {

    if (isResizingRef.current || !isCurrentlyDragging || !clientOffset || !item || itemType !== "THEME" || item.id !== theme.id) {
      return;
    }

    if (
      isCurrentlyDragging &&
      clientOffset &&
      canvasContentRef.current &&
      item &&
      itemType === "THEME" && 
      item.id === theme.id
    ) {
      const canvasRect = canvasContentRef.current.getBoundingClientRect();
      const left = (clientOffset.x - canvasRect.left - initialOffsetRef.current.x) / zoomLevelRef.current;
      const top = (clientOffset.y - canvasRect.top - initialOffsetRef.current.y) / zoomLevelRef.current;
      
      setCurrentPosition({ left, top });
    }
  }, [clientOffset, isCurrentlyDragging, item]);

  useEffect(() => {
    if (!isCurrentlyDragging) {
      setCurrentPosition({ left: theme.left, top: theme.top });
    }
  }, [theme.left, theme.top, isCurrentlyDragging]);



  useEffect(() => {
    const handleMouseUp = () => {
      if (isResizing) {
       
         setIsResizingState(false);
      }
    };

   
  
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);
  

  const handleResizeStart = (event, data) => {
    setIsResizingState(true);
   

    // You can add additional logic here if needed
  };
  
  const handleResizeStop = (event, data) => {
   
    setIsResizingState(false);
    // Update the backend or state if necessary after resize
  };
  
  const onResizeAbsolute = (event, {element, size, handle}) => {

    if (!isResizingRef.current) return;

    if (isResizing) {
    
      setWidth(size.width);
      setHeight(size.height);
    }
  };
  
  const handleResizeMouseDown = (event, direction) => {
    event.stopPropagation();
    setIsResizingState(true);
    const initialX = event.clientX;
    const initialY = event.clientY;
  
    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - initialX;
      const deltaY = moveEvent.clientY - initialY;
      
      setWidth((prevWidth) => Math.max(150, prevWidth + (direction.includes("e") ? deltaX : direction.includes("w") ? -deltaX : 0)));
      setHeight((prevHeight) => Math.max(200, prevHeight + (direction.includes("s") ? deltaY : direction.includes("n") ? -deltaY : 0)));
    };
    
  };


  const updateQuoteColumnIndex = (quoteId, newColumnIndex) => {
   
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === quoteId ? { ...quote, columnIndex: newColumnIndex } : quote
      )
    );
  };

  return (
    <Resizable
      width={width}
      height={height}
      axis="both" 
      onResize={onResizeAbsolute}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      resizeHandles={[ 'se',   'e', 's']}  
      // minConstraints={[150, 200]} // Optional minimum dimensions
      // maxConstraints={[800, 600]} // Optional maximum dimensions
    >
      
      <div
        ref={(node) => {
          if (!isResizingRef.current) {
            drag(drop(node));
          }

          themeRef.current = node;
        }}
        className={`theme-container`}
        style={{
          position: "absolute",
          width: `${width}px`, 
          height: `${height}px`,    // Dynamically set width
          left: currentPosition.left,
          top: currentPosition.top,
          borderRight: highlightEdge === "right" ? "3px dashed blue" : "none",
          borderBottom: highlightEdge === "bottom" ? "3px dashed blue" : "none",
        }}
      >
        <div className="theme-header-row"
        ref={headerRef}
        style={{
          backgroundColor: backgroundColor,
          borderTop: `2px solid ${borderColor}`,
          borderLeft: `2px solid ${borderColor}`,
          borderRight: highlightEdge === "right" ? "3px dashed blue" : `2px solid ${borderColor}`,
          borderBottom: highlightEdge === "bottom" ? "3px dashed blue" : `2px solid ${borderColor}`,
        }}
        >
          <div className="theme-header">
            {isEditing ? (
              <textarea
               
                value={theme.title}
                onChange={handleTitleChange}
                onBlur={() => setIsEditing(false)}
                autoFocus
                className="theme-header-input"
              />
            ) : (
              <h3 onClick={() => setIsEditing(true)}>{theme.title}</h3>
            )}
          </div>
        </div>
        
        <div className="quotes-container" 
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: backgroundColor,
            borderTop: `2px solid ${borderColor}`,
            borderLeft: `2px solid ${borderColor}`,
            borderRight: highlightEdge === "right" ? "3px dashed blue" : `2px solid ${borderColor}`,
            borderBottom: highlightEdge === "bottom" ? "3px dashed blue" : `2px solid ${borderColor}`,
          }}>
        {Array.from({ length: Math.ceil(width / 200) }).map((_, colIndex) => (
          <div key={colIndex} className="quote-column" style={{ width: '250px' }}>
            {quotes
              .filter((quote) => quote.columnIndex === colIndex)
              .sort((a, b) => a.order - b.order || 0)
              .map((quote,index) => (
                <AffinityQuote
                  key={quote.id}
                  index={index}
                  quote={quote}
                  themeId={theme.id}
                  columnIndex={colIndex}
                  moveQuote={moveQuote}
                  updateQuoteColumnIndex={updateQuoteColumnIndex}
                  updateQuotePosition={updateQuotePosition}
                  updateQuoteTheme={updateQuoteTheme}
                  zoomLevel={zoomLevel}
                  canvasContentRef={canvasContentRef}
                  activeTool={activeTool}
                  isInTheme={true}
                  setQuoteHeight={(height) => handleSetQuoteDimensions(quote.id, colIndex, height)}
                />
              ))}
            </div>
          ))}
        </div>



      </div>
    </Resizable>
  );
};

export default AffinityTheme;
