import React from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ThreeDots } from 'react-loader-spinner';

const QuestionsStep = ({
  questions,
  setQuestions,
  newQuestion,
  setNewQuestion,
  handleAddQuestion,
  editIndex,
  setEditIndex,
  handleQuestionChange,
  handleDeleteQuestion,
  isGeneratingQuestions,
  handleOnDragEnd,
}) => {
  return (
    <div className="questions-step">
      {isGeneratingQuestions ? (
        <div className="loader-container">
          <ThreeDots color="#6419F7" height={50} width={50} /> 
          <p>Generating questions...</p>
        </div>
      ) : (
        <div className="left-column">
          <div className="step-label">Review Research Questions</div>
          <p className="description">
          Since this is a conversational flow, the questions here would not be asked verbatim or in the order shown here. Rest assured all the topics given here would get covered. You can experience this while giving a Test Interview in the later steps.
          </p>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions && questions.length > 0 && questions.map((question, index) => (
                    <Draggable key={question.id} draggableId={question.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="question-box"
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ flex: 1 }}>
                              {editIndex === index ? (
                                <>
                                  <textarea
                                    value={question.question}
                                    onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                    onBlur={() => setEditIndex(null)}
                                    autoFocus
                                  />
                                  <textarea
                                    value={question.followup}
                                    onChange={(e) => handleQuestionChange(index, 'followup', e.target.value)}
                                    onBlur={() => setEditIndex(null)}
                                    placeholder="Follow-up instruction (optional)"
                                    className="followup-input"
                                  />
                                </>
                              ) : (
                                <>
                                  <span>{question.question}</span>
                                  {question.followup && (
                                    <em className="followup-text">
                                      Potential Followup: {question.followup}
                                    </em>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="icons">
                              <FaEdit className="edit_icon" onClick={() => setEditIndex(index)} />
                              <FaTrashAlt className="del_icon" onClick={() => handleDeleteQuestion(index)} />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <textarea
            className="new-question-input"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            placeholder="Type your question here"
          />
          <div className="add-question-container">
            <button className="add-question-button" onClick={handleAddQuestion}>
              Add Question
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsStep;
