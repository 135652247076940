
export const educationOptions = [
    { value: 'somehighschool', label: 'Some High School' }, 
    { value: 'somecollegenodegree', label: 'Some College no degree' }, 
    { value: 'highschoolgraduate', label: 'High School Graduate' }, 
    { value: 'associatedegree', label: 'Associate Degree' }, 
    { value: 'bachelordegree', label: 'Bachelor Degree' }, 
    { value: 'masterdegree', label: 'Masters Degree' },
    { value: 'doctoratedegree', label: 'Doctorate Degree' },
    { value: 'tradetechnicalvocationaltraining', label: 'Technical and Vocational Training' },
  ];

  export const incomeRangeOptions = [

    { value: 'Below 30,000', label: 'Below 30,000' }, 
    { value: '30,000-39,999', label: '30,000-39,999' }, 
    { value: '40,000-59,999', label: '40,000-59,999' },
    { value: '60,000-79,999', label: '60,000-79,999' }, 
    { value: '80,000-99,999', label: '80,000-99,999' }, 
    { value: '100,000-149,999', label: '100,000-149,999' }, 
    { value: '150,000-199,999', label: '150,000-199,999' }, 
    { value: 'Above 200,000', label: 'Above 200,000' }, 
  ];

  export const occupationOptions = [{ value: 'Full-time', label: 'Full-time' }, { value: 'Part-time', label: 'Part-time' }];
  export const countryOptions = [{ value: 'United States', label: 'United States' }, { value: 'Canada', label: 'Canada' }];
  export const stateOptions = [{ value: 'All', label: 'All' }, { value: 'California', label: 'California' }];

  export const criteriaOptions = ["Country", "Age", "Household Income", "Gender", "Language", "Education Level"];

  export const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },

  ];
  
  export const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },

  ];

  export const multipleChoiceActionOptions = [
    { value: 'may-select', label: 'May select' },
    { value: 'must-select', label: 'Must select' },
    { value: 'disqualify', label: 'Disqualify' }
  ];
  
  export const singleChoiceActionOptions = [
    { value: 'qualify', label: 'Qualify' },
    { value: 'disqualify', label: 'Disqualify' }
  ];
  
  
  export const questionTypeOptions = [
    { value: 'multiple', label: 'Multiple Choice' },
    { value: 'single', label: 'Single Choice' },
    { value: 'short', label: 'Short Answer' },
    { value: 'long', label: 'Long Answer' }
  ];