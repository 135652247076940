import axios from 'axios';

export const createCampaign = async ({ selectedBlock, campaignName, getAccessTokenSilently, getIdTokenClaims, projectType }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const url = 'https://dashboard-api.echovane.com/api/campaigns';
  
      const method = 'POST';
  
      const typeMapping = {
        1: 'consumer_research',
        2: 'ad_testing',
        3: 'concept_testing',
        4: 'consumer_feedback',
      };
  
      const campaignData = {
        name: campaignName,
        type: selectedBlock ? typeMapping[selectedBlock] : null,
        project_type: projectType
      };
  
      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create/update campaign: ${errorText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
      throw error;
    }
  };
  

export const createInterview = async({ campaignId, getAccessTokenSilently, getIdTokenClaims, interviewType }) => {

  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          interview_type: interviewType  
      })
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to create interview: ${errorText}`);
    }

    return await response.json();

  }
  catch (error) {
    console.error('Error creating interview:', error);
    throw error;
  }

};


export const updateCampaign = async ({campaignData, campaignId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
          method: 'PUT',
          headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(campaignData)
      });

      if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Failed to update campaign: ${response.status} ${response.statusText}`);
      }

      const updatedCampaign = await response.json();
      return updatedCampaign;
  } catch (error) {
      console.error('Error updating campaign:', error);
      throw error;
  }
};


export const fetchTranscripts = async ({interviewId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;
    if (interviewId) {
    const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${interviewId}/transcripts`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch transcripts');
    }

    const data = await response.json();
    
    return data;
  }
  else {
    // setError('Hey there! It looks like there are no transcripts yet.');
  }
  } catch (error) {
    console.error('Error fetching transcripts:', error);
    // setError('Failed to load transcripts. Please try again later.');
  } 
};

export const fetchCampaignData = async ({campaignId, getAccessTokenSilently, getIdTokenClaims}) => {
  console.log("Caaa ", campaignId);
  if (campaignId) {
    try {
      
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const fetchedCampaign = await response.json();
        console.log("campaign data:", fetchedCampaign);
        return fetchedCampaign;
      } else {
        console.error('Failed to fetch campaign data');
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error);
    } 
  }
};


export  const fetchAudioUrl = async ({transcriptId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims();

    const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/transcripts/${transcriptId}/presigned-url`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken.__raw,
      }
    });

    const data = await response.json();
    console.log("audio url ", data);
 
    if (!response.ok) {
      throw new Error('Failed to fetch audio URL');
    }
     return data;

  } catch (error) {
    console.error('Error fetching audio URL:', error);
  } 
};


export const generateCampaignQuestions = async ({campaignId, getAccessTokenSilently, getIdTokenClaims, instruction}) => {
  const payload = instruction ? { instruction } : {};

  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/generate-questions`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-ID-Token': idToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to generate questions: ${errorText}`);
  }

  const data = await response.json();
  return data.questions;
};

export const createOrUpdateCampaign = async ({campaignId, getAccessTokenSilently, getIdTokenClaims, campaignData}) => {

  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  const url = campaignId && campaignId !== 'null'
    ? `https://dashboard-api.echovane.com/api/campaigns/${campaignId}`
    : 'https://dashboard-api.echovane.com/api/campaigns';

  const method = campaignId ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method: method,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-ID-Token': idToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(campaignData)
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to create/update campaign: ${errorText}`);
  }

  const data = await response.json();
  return data;
};

export const createOrUpdateInterview = async ({interviewId, campaignId, getAccessTokenSilently, getIdTokenClaims, isTestInterview}) => {

  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  if (interviewId) {
    // Update existing interview
    const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${interviewId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.log("Error details:", errorDetails);
      if (errorDetails.detail && errorDetails.detail.error_code === "INTERVIEW_MINUTES_EXCEEDED") {
        // Handle specific error case here
        alert("Sorry, you have used your interview minutes. Upgrade to create interview links.");
      } else {
        console.error("An unexpected error occurred:", errorDetails);
      }
      throw new Error('Failed to update the interview');
    }

    const updatedInterview = await response.json();
    return updatedInterview;
  } else {
    // Create new interview
    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        interview_type: isTestInterview ? "test" : "live"
      })
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.log("Error details:", errorDetails);
      if (errorDetails.detail && errorDetails.detail.error_code === "INTERVIEW_MINUTES_EXCEEDED") {
        // Handle specific error case here
        alert("Sorry, you have used your interview minutes. Upgrade to create interview links.");
      } else {
        console.error("An unexpected error occurred:", errorDetails);
      }
      throw new Error('Failed to create the interview');
    }

    const newInterview = await response.json();
    return newInterview;
  }
};


export const requestPresignedUrls = async ({ interviewId, fileNames, fileSizes, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    const response = await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/presigned-urls`,
      { interview_id: interviewId, file_names: fileNames, file_sizes: fileSizes },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error requesting presigned URLs:', error);
    throw error;
  }
};

export const completeMultipartUpload = async ({ interviewId, transcriptId, fileName, uploadId, etags, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/complete-multipart-upload`,
      {
        interview_id: interviewId,
        transcript_id: transcriptId,
        file_name: fileName,
        upload_id: uploadId,
        parts: etags,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error(`Error completing multipart upload for ${fileName}:`, error);
    throw error;
  }
};

export const abortMultipartUpload = async ({ interviewId, transcriptId, fileName, uploadId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/abort-multipart-upload`,
      {
        interview_id: interviewId,
        transcript_id: transcriptId,
        file_name: fileName,
        upload_id: uploadId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error(`Error aborting multipart upload for ${fileName}:`, error);
    throw error;
  }
};

export const analyzeInterviews = async ({ campaignId, interviewId, refreshAnalysis = false, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/analyze/analyze-interviews`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        campaign_id: campaignId,
        interview_id: interviewId,
        refresh_analysis: refreshAnalysis,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to analyze interviews: ${response.status} ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error during interview analysis:', error);
    throw error;
  }
};

export const deleteTranscript = async ({ transcriptId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.delete(
      `https://dashboard-api.echovane.com/api/interviews/transcripts/${transcriptId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error('Error deleting transcript:', error);
    throw error;
  }
};