import React from 'react';
import './ReportsPage.css'; // You can style the empty message here
import empty_reports from '../assets/empty_reports.png';

const ReportsPage = () => {
  return (
    <div className="empty-page">
      <div className="empty-content">
        <img src={empty_reports} className='empty_image'></img>
        <h2 className="empty-title">No Reports Available</h2>
        <p className="empty-description">
          There are currently no reports to show. Once reports are created, they’ll be available here for you to view.
        </p>
      </div>
    </div>
  );
};

export default ReportsPage;
