// ProgressHeader.jsx
import React from 'react';
import { FaSave, FaArrowLeft } from 'react-icons/fa';
import { ThreeDots } from 'react-loader-spinner';

const ProgressHeader = ({
  handleBackClick,
  title,
  setTitle,
  steps,
  step,
  handleStepChange,
  handleSave,
  continueStep,
  loading,
  handleSubmit,
  showSaveButton = true,
  saveButtonLabel = 'Save',
  continueButtonLabel = 'Continue',
  backButtonLabel = 'Back',
  additionalButtons = null,
  filterSteps = null, // Optional function to filter steps
  classNamePrefix = '', // Optional prefix for class names to handle styling differences
}) => {
  return (
    <div className={`${classNamePrefix}progress-header-container`}>
      <div className={`${classNamePrefix}header`}>
        <button className={`${classNamePrefix}back-button`} onClick={handleBackClick}>
          &#8592; {backButtonLabel}
        </button>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Untitled"
          className={`${classNamePrefix}title-input`}
        />
      </div>
      <div className={`${classNamePrefix}progress-container`}>
        <div className={`${classNamePrefix}progress-indicator`}>
          {steps
            .filter((stepName, index) => (filterSteps ? filterSteps(stepName, index) : true))
            .map((stepName, index) => (
              <span
                key={index}
                className={`step ${step === index ? 'current-step' : ''} ${
                  index <= step ? 'completed' : 'incomplete'
                }`}
                onClick={() => handleStepChange(index)}
              >
                {stepName}
                {index < steps.length - 1 && (
                  <span className={`separator ${index <= step ? 'completed' : 'incomplete'}`}>
                    {' '}
                    &gt;{' '}
                  </span>
                )}
              </span>
            ))}
        </div>
        <div className={`${classNamePrefix}button-container`}>
          {step > 0 && (
            <button
              className={`${classNamePrefix}back-button`}
              onClick={() => handleStepChange(step - 1)}
            >
              <FaArrowLeft /> {backButtonLabel}
            </button>
          )}
          {showSaveButton && (
            <button className={`${classNamePrefix}save-button`} onClick={handleSave}>
              <FaSave /> {saveButtonLabel}
            </button>
          )}
          <button
            className={`${classNamePrefix}continue-button`}
            onClick={step === steps.length - 1 && handleSubmit ? handleSubmit : continueStep}
            disabled={loading}
          >
            {loading ? (
              <ThreeDots color="#fff" height={10} width={24} />
            ) : step === steps.length - 1 ? (
              continueButtonLabel
            ) : (
              'Continue'
            )}
          </button>
          {additionalButtons}
        </div>
      </div>
    </div>
  );
};

export default ProgressHeader;
