// In PostLiveStep component
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import consumer_research from '../../assets/consumer_research_p.svg';
import edit_icon from '../../assets/edit_icon_color.svg';
import live_copy from '../../assets/live_icon_color.svg';
import test_copy from '../../assets/test_icon_color.svg';
import report_icon from '../../assets/report_icon_color.svg';

const PostLiveStep = ({ handleBackClick,
  campaign }) => {
  const navigate = useNavigate();
  const [copiedMessage, setCopiedMessage] = useState('');

  const handleEditCampaign = () => {
    // Navigate to campaign builder with step 0
    navigate(`/research/${campaign.id}?step=0`);
  };

  const handleCopyLink = (linkType) => {
    const link =
      linkType === 'live'
        ? `https://feedback.echovane.com/interviews/${campaign.live_link}`
        : `https://feedback.echovane.com/interviews/${campaign.test_link}`;

    navigator.clipboard.writeText(link);
    setCopiedMessage(`${linkType.charAt(0).toUpperCase() + linkType.slice(1)} link copied`);


    setTimeout(() => {
      setCopiedMessage('');
    }, 3000);
    
  };




  const handleViewReport = () => {
    navigate(`/report/${campaign.id}`);
  };

  return (
    <><div className="builder-header">
      <button className="back-button-campaign-builder" onClick={handleBackClick}>
        &#8592;
      </button>

      <input
        type="text"
        value={campaign?.name || 'Untitled'}
        disabled
        placeholder="Add project name"
        className="campaign-name-input" />

    </div><div className="post-live-step">

        <div className="step-label">{campaign?.name || 'Untitled'} is live!</div>

        <div className='post-live-actions-section'>
          <div className='post-live-section-title'>Manage project</div>
          <div className='post-live-section-description'> Manage your project seamlessly with these essential actions. Edit project details, share live link with your audience, test your interview flow, and view Insights Report</div>
          <div className="post-live-actions-container">

            <div className="post-live-actions-item" onClick={handleEditCampaign}>
              <div className='post-live-actions-item-content'>
                <img src={edit_icon} className='post-live-actions-item-svg'></img>
                <p className='post-live-actions-item-text'>Edit Project</p>
                <div className='hover-description'>
                  Edit the project details, including business objective, recruitment criteria, or screener questions.

                </div>
              </div>
            </div>

            <div className="post-live-actions-item" onClick={() => handleCopyLink('live')}>
              <div className='post-live-actions-item-content'>
                <img src={live_copy} className='post-live-actions-item-svg'></img>
                <p className='post-live-actions-item-text'>Copy Live Link</p>
                <div className='hover-description'>
                  Share this live link with your audience to collect real responses.

                </div>
              </div>
            </div>

            <div className="post-live-actions-item" onClick={() => handleCopyLink('test')}>
              <div className='post-live-actions-item-content'>
                <img src={test_copy} className='post-live-actions-item-svg'></img>
                <p className='post-live-actions-item-text'>Copy Test Link</p>
                <div className='hover-description'>
                  Use this test link to preview and refine the interview flow before sharing.

                </div>
              </div>
            </div>

            <div className="post-live-actions-item" onClick={handleViewReport}>
              <div className='post-live-actions-item-content'>
                <img src={report_icon} className='post-live-actions-item-svg'></img>
                <p className='post-live-actions-item-text'>View Report</p>
                <div className='hover-description'>
                  Access detailed insights and transcripts along with recordings from completed conversations.

                </div>
              </div>
            </div>
          </div>
        </div>
        {copiedMessage && (
              <div className="copied-message">
                {copiedMessage}
              </div>
            )}
      </div></>
      
  );
};

export default PostLiveStep;
