import React from 'react';
import './KnowledgeBasePage.css'; 
import empty_reports from '../assets/empty_reports.png';

const KnowledgeBasePage = () => {
  return (
    <div className="empty-page">
      <div className="empty-content">
        <img src={empty_reports} className='empty_image'></img>
        <h2 className="empty-title">Nothing added to the KnowledgeBase yet.</h2>
        <p className="empty-description">
          There are currently no articles to show. Once you upload articles, they’ll be available here for you to view.
        </p>
      </div>
    </div>
  );
};

export default KnowledgeBasePage;
