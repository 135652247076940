import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdOutlineHome, MdOutlineBarChart,MdOutlineMenuBook, MdSearch, MdChevronLeft, MdChevronRight, 
  MdAccountCircle, MdContentCopy, MdMoreVert, MdArrowDropUp, MdArrowDropDown, 
  MdNewLabel, MdLabel, MdArrowRight, MdArrowLeft,MdExitToApp , MdArrowUpward} from 'react-icons/md';
import logo from '../assets/echovane_logo_white.svg';
import mixpanel from 'mixpanel-browser';
import ReportsPage from './ReportsPage';
import HomePage from './HomePage';
import KnowledgeBasePage from './KnowledgeBasePage';
import logoSmall from '../assets/echovane_logo_small_white_outline.svg';
import axios from 'axios';
import { Home, BarChart, Book } from 'react-feather';
import Subscriptions from './Subscriptions'; 


mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });



const AppContent = () => {
  const location = useLocation();

  
  useEffect(() => {
    mixpanel.track('DashboardHomePageViewed');
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false); // For expanding and collapsing drawer

  const handleUpgrade = () => {
    setCurrentView('Subscriptions');
    navigate('/subscriptions', { replace: true });
  };
  
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
    setShowText(true);  // Display text when drawer is expanded
  };
  
  const closeDrawer = () => {
    setDrawerOpen(false);
    setShowText(false);  // Hide text when drawer is collapsed
  };
  
  
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false); // For user dropdown
  const [showText, setShowText] = useState(true);
  const drawerRef = useRef(null);
  const [currentView, setCurrentView] = useState('Home'); 
  const [interviewMinutesUsed, setInterviewMinutesUsed] = useState(0);
  const [interviewMinutesLimit, setInterviewMinutesLimit] = useState(0); 
  const [planType, setPlanType]= useState('Free');
  const [interviewUsagePercent, setInterviewUsagePercent] = useState(0);
  const [balance, setBalance] = useState(0);
  



  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      setCurrentView('Subscriptions'); // Set view to Subscriptions

      // Optionally verify payment here with sessionId if needed

      // Remove session_id from URL to prevent re-triggering
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const fetchUsageData = async () => {

      if (accessToken) {
        try {

          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          
          const response = await fetch('https://dashboard-api.echovane.com/api/usage', {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken.__raw,
            },
          });
          const data = await response.json();

          console.log("usage ", data);
  
          setInterviewMinutesUsed(data.interview_minutes_used);
          setInterviewMinutesLimit(data.interview_minutes_limit);
          setPlanType(data.plan_name);
  
          const usagePercentage = (data.interview_minutes_used / data.interview_minutes_limit) * 100;
          setInterviewUsagePercent(usagePercentage);
        } catch (error) {
          console.error('Error fetching usage data:', error);
          setError('Failed to fetch usage data. Please try again.');
        }
      }
    };
  
    fetchUsageData();
  }, [accessToken]);

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setUserMenuOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!isLoading && !isAuthenticated) {
        try {
          const queryParams = new URLSearchParams(location.search);
          const screenHint = queryParams.get('screen_hint');
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname },
            authorizationParams: {
              ...(screenHint && { screen_hint: screenHint }),
            },
          });
        } catch (error) {
          console.error('Login error:', error);
          setError('Failed to log in. Please try again.');
        }
      }
    };

    checkAndRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
        
          setIdToken(idTokenClaims.__raw);
          setAccessToken(accessToken);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setError('Failed to fetch authentication tokens. Please try logging in again.');
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, user]);




  useEffect(() => {
    const drawer = drawerRef.current;

    if (drawer) {
    if (drawerOpen) {
  
      const handleTransitionEnd = () => {
        setShowText(true);
      };
      
      drawer.addEventListener('transitionend', handleTransitionEnd);
  
      return () => {
       
        drawer.removeEventListener('transitionend', handleTransitionEnd);
      };
    } else {
      
      setShowText(false);
    }

  }
  }, [drawerOpen]);


  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  

  return (
    <>      
      { 
        <div className="app-container">

          <div className='drawer-container'>
          
          <div ref={drawerRef} 
            className={`drawer ${drawerOpen ? 'expanded' : 'collapsed'}`}
            onMouseEnter={openDrawer}
            onMouseLeave={closeDrawer}
          >
            <div className="logo-section">
            <img
              src={drawerOpen ? logo : logoSmall}
              alt="Echovane Logo"
              className="logo"
              style={!drawerOpen ? { width: '30px', height: '30px' } : {}}
            />


            </div>

            <div className='bold-line'></div>

            <nav className="nav-menu">
              <button className="nav-item" onClick={() => setCurrentView('Home')}>
                <Home className='nav-item-svg' />
                {showText && drawerOpen && <span>Home</span>}
              </button>
              <div className='thin-line'></div>
              <button className="nav-item" onClick={() => setCurrentView('Reports')}>
                <BarChart  className='nav-item-svg' />
                {showText && drawerOpen && <span>Reports</span>}
              </button>
              <div className='thin-line'></div>
              <button className="nav-item" onClick={() => setCurrentView('KnowledgeBase')}>
                <Book className='nav-item-svg' />
                {showText && drawerOpen && <span>Knowledge Base</span>}
              </button>
              <div className='thin-line'></div>
            </nav>

            {drawerOpen && (
              <div className="usage-meter-box">
                <div className="usage-item">
                  <div className="label">Interview Minutes</div>
                  <div className="meter">
                    <div className="meter-bar minutes" style={{ width: `${interviewUsagePercent}%` }}></div>
                  </div>
                  <div className="meter-value">
                      {interviewMinutesUsed} / {interviewMinutesLimit} mins
                    </div>
                </div>
              </div>

          )}

            {drawerOpen && (
            <div className="recruit-credits-box">
              <div className="usage-item">
                <span className="label">Recruit Balance : $0</span>
              </div>
        
            </div>
          )}


            {/* User Section */}
            <div ref={menuRef} className="user-section" onClick={toggleUserMenu}>
              <button className="user-initial-circle" >
                <span>{user?.email.charAt(0).toUpperCase()}</span>
                </button>

                {drawerOpen && (
                <div className="user-email">{user?.email}</div> 
              )}

              {
                userMenuOpen?  <MdArrowDropDown/>:<MdArrowDropUp/>
              }

              {userMenuOpen && (
                <div className="user-menu-dropdown">
                  {/* <button className='user-menu-button' onClick={() => navigate('/account')}>
                  <MdAccountCircle /> Account
                  </button>

                  <button className='user-menu-button'   onClick={() => navigate('/plans')}>
                  <MdNewLabel />     Plans
                  </button>

                  <button className='user-menu-button' onClick={() => setCurrentView('Subscriptions')}>
                    <MdArrowUpward /> Upgrade
                  </button> */}

                  <button className='user-menu-button'  onClick={handleLogout}> 
                  <MdExitToApp />  Logout
                  </button>
                </div>
              )}

            </div>

          </div>
           </div>

          <main className="main-content">
          {currentView === 'Home' && (
            <HomePage
            interviewMinutesUsed={interviewMinutesUsed}
            interviewMinutesLimit={interviewMinutesLimit}
            planType={planType} 
          />
          )}

            {currentView === 'Reports' && (
              <ReportsPage/>
              )}

              {currentView === 'KnowledgeBase' && (
                <KnowledgeBasePage/>
              )}
              {currentView === 'Subscriptions' && <Subscriptions />}

          </main>
          </div>
        
        
      }

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
    </>
  );
  
}

export default AppContent;