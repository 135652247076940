import React from 'react';

const IdentifyStep = ({
  identificationMethod,
  setIdentificationMethod,
  selectedFieldsForLink,
  setSelectedFieldsForLink,
  selectedFieldsForAsk,
  setSelectedFieldsForAsk,
  suggestedFieldsForLink,
  setSuggestedFieldsForLink,
  suggestedFieldsForAsk,
  setSuggestedFieldsForAsk,
  customField,
  setCustomField,
  handleAddField,
  handleRemoveField,
  handleAddCustomField,
  isCustomField,
  setIsCustomField,
}) => {
  return (
    <div className="identify-step">
    <div className='step-label'>Identify Respondents</div>
    <div className="identify-options">

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="link"
          checked={identificationMethod === 'link'}
          onChange={() => setIdentificationMethod('link')}
        />
        <span className="custom-radio-btn"></span> 
        Add identification fields to the interview link
      </label>

      {identificationMethod === 'link' && (
        <div className="fields-section">
          <div className="selected-fields">
            {selectedFieldsForLink.length > 0 ? (
              selectedFieldsForLink.map((field, index) => (
                <span key={index} className="chip">
                  {field}
                  <span className="remove-icon" onClick={() => handleRemoveField(field, "link")}>x</span>
                </span>
              ))
            ) : (
              <div className="empty-fields-hint">No fields added yet</div>
            )}
          </div>
          <hr className='hr-dash'></hr>

          <div className="suggest-label">Pick from one of the fields below or add a custom field of your choice</div>
          <div className="suggested-fields">
            {suggestedFieldsForLink.map((field, index) => (
              <span key={index} className="chip" onClick={() => handleAddField(field, true, 'link')}>
                {field} <span className="add-icon">+</span>
              </span>
            ))}
          </div>

          <div className="custom-field-input">
            <input
              type="text"
              value={customField}
              onChange={(e) => setCustomField(e.target.value)}
              placeholder="Enter custom field"
            />
            <button className= "add-button" onClick={() => handleAddCustomField("link")}>Add</button>

          </div>


        </div>
      )}

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="ask"
          checked={identificationMethod === 'ask'}
          onChange={() => setIdentificationMethod('ask')}
        />
        <span className="custom-radio-btn"></span> 
        Ask for information from the respondent
      </label>

      {identificationMethod === 'ask' && (
        <div className="fields-section">
          {/* Selected fields section */}
          <div className="selected-fields">
            {selectedFieldsForAsk.length > 0 ? (
              selectedFieldsForAsk.map((field, index) => (
                <span key={index} className="chip">
                  {field}
                  <span className="remove-icon" onClick={() => handleRemoveField(field, "info")}>x</span>
                </span>
              ))
            ) : (
              <p className="empty-fields-hint">No fields added yet</p>
            )}
          </div>
          <hr className='hr-dash'></hr>

          <div className="suggest-label">Pick from one of the fields below or add a custom field of your choice</div>
          <div className="suggested-fields">
            {suggestedFieldsForAsk.map((field, index) => (
              <span key={index} className="chip" onClick={() => handleAddField(field, true, 'info')}>
                {field} <span className="add-icon">+</span>
              </span>
            ))}
          </div>

          <div className="custom-field-input">
            <input
              type="text"
              value={customField}
              onChange={(e) => setCustomField(e.target.value)}
              placeholder="Enter custom field"
            />
            <button className= "add-button"  onClick={() => handleAddCustomField("info")}>Add</button>
          </div>


        </div>
      )}

      <label className = "identify-option-label custom-radio">
        <input
          type="radio"
          value="none"
          checked={identificationMethod === 'none'}
          onChange={() => setIdentificationMethod('none')}
        />
        <span className="custom-radio-btn"></span> 
        No need to identify
      </label>

    </div>
  </div>
  );
};

export default IdentifyStep;
