// Participants.js

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdArrowBack, MdMoreVert} from 'react-icons/md';
import './Participants.css';
import { format } from 'date-fns';
import {
  FaGenderless,
  FaBriefcase,
  FaBuilding,
  FaUniversity,
  FaDollarSign,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import { ThreeDots } from 'react-loader-spinner'; 

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [loadingAction, setLoadingAction] = useState(null); // Will store participant ID and action type


  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { campaignId } = location.state || {};
  const [actionMenuOpen, setActionMenuOpen] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [tempStatuses, setTempStatuses] = useState({});

  const menuRef = useRef(null);

  

  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();

  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setActionMenuOpen(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (campaignId && isAuthenticated) {
      fetchParticipants();
    }
  }, [campaignId, isAuthenticated]);

  const fetchParticipants = async () => {
    if (isInitialLoad) setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(
        `https://dashboard-api.echovane.com/api/campaigns/${campaignId}/participants`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log('participants data', data);
        setParticipants(data);
      } else {
        console.error('Failed to fetch participants');
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoading(false);
      setIsInitialLoad(false); 
    }
  };

  const getActionMenuOptions = (status, participantId) => {
    switch (status) {
      case 'PENDING':
        return [
          { label: 'Invite', action: () => handleInviteParticipant(participantId) },
          { label: 'Mark Completed & Payout', action: () => handleMarkComplete(participantId) },
          { label: 'Report', action: () => handleReportParticipant(participantId) }
        ];
      case 'INVITED':
        return [
          { label: 'Mark Completed & Payout', action: () => handleMarkComplete(participantId) },
          { label: 'Report', action: () => handleReportParticipant(participantId) }
        ];
      case 'COMPLETED':
        return [{ label: 'Report', action: () => handleReportParticipant(participantId) }];
      case 'REPORTED':
        return []; // No options, so we’ll hide the menu
      default:
        return [];
    }
  };


  const toggleActionMenu = (participantId) => {
    setActionMenuOpen(actionMenuOpen === participantId ? null : participantId);
  };
  

  const handleParticipantClick = (participant) => {
    setSelectedParticipant(participant);
  };

  const handleCloseSideSheet = () => {
    setSelectedParticipant(null);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleInviteParticipant = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'invite' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/invite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        // Re-fetch participants to get updated status from backend
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'INVITED' }));
        await fetchParticipants();
      } else {
        const errorData = await response.json();
        alert(`Failed to invite participant: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error inviting participant:', error);
      alert('An error occurred while inviting the participant.');
    } finally {
      setLoadingAction(null); // Reset after API call completes
    }
  };

  const handleMarkComplete = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'markComplete' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/mark_completed`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'COMPLETED' }));
        await fetchParticipants(); // Refresh participants list
      } else {
        const errorData = await response.json();
        alert(`Failed to mark participant as complete: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error marking participant as complete:', error);
    } finally {
      setLoadingAction(null); // Reset loading state
      setActionMenuOpen(null); // Close action menu
    }
  };
  
  
  const handleReportParticipant = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'report' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/report`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'REPORTED' }));
        await fetchParticipants(); // Refresh participants list
      } else {
        const errorData = await response.json();
        alert(`Failed to report participant: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error reporting participant:', error);
    } finally {
      setLoadingAction(null); // Reset loading state
      setActionMenuOpen(null); // Close action menu after completion
    }
  };
  
  
  
  

  return (
    <div className="participants-container">
      {/* Header */}
      <div className="participants-header">
        <button onClick={handleBack} className="back-button">
          &#8592;
        </button>
        <div className="header-title">Participants</div>
      </div>

      {isInitialLoad && loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="participants-list">
          {participants.length === 0 ? (
            <div className="empty-message">
              No respondents have applied for the research yet, please check back later after some
              time.
            </div>
          ) : (
            participants.map((participant) => 

              {
                // Determine button text and if the button should be disabled
                let buttonText = 'Invite';
                let isButtonDisabled = false;
                let buttonClass = 'invite-button'; // Default button class
                let status = tempStatuses[participant.id] || participant.status;
                const actionMenuOptions = getActionMenuOptions(status, participant.id);

                // console.log("status value ", status);

                switch (status) {
                  case 'PENDING':
                    buttonText = 'Invite';
                    isButtonDisabled = false;
                    buttonClass = 'pending-button';
                    break;
                  case 'INVITED':
                    buttonText = 'Invited';
                    buttonClass = 'invited-button';
                    isButtonDisabled = true;
                    break;
                  case 'COMPLETED':
                    buttonText = 'Completed';
                    buttonClass = 'completed-button';
                    isButtonDisabled = true;
                    break;
                  case 'NOT COMPLETED':
                    buttonText = 'Not completed';
                    buttonClass = 'not-completed-button';
                    isButtonDisabled = true;
                    break;
                  case 'REPORTED':
                    buttonText = 'Reported';
                    buttonClass = 'reported-button';
                    isButtonDisabled = true;
                    break;
                  default:
                    buttonText = 'OTHER';
                    isButtonDisabled = true;
                    buttonClass = 'disabled-button'; // Black background for disabled buttons
                    break;
                }

                return (
              <div
                key={participant.id}
                className="participant-card"
                onClick={() => handleParticipantClick(participant)}
              >
                {/* Invite Button and Applied Date */}
                <div className="card-actions-container">
                <div className="card-actions">
                <button
                    className={buttonClass}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isButtonDisabled) {
                        handleInviteParticipant(participant.id);
                      }
                    }}
                    disabled={isButtonDisabled || (loadingAction && loadingAction.id === participant.id)}

                  >
                  {loadingAction && loadingAction.id === participant.id ? (
                    <ThreeDots className="three-dots" color="#fff" height={10} width={24} />
                  ) : (
                    buttonText
                  )}

                  </button>

                  {actionMenuOptions.length > 0 && (
                    <div ref={menuRef}  className="more-options">
                      <MdMoreVert
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActionMenu(participant.id);
                        }}
                      />
                      {actionMenuOpen === participant.id && (
                        <div className="action-menu-participants">
                          {actionMenuOptions.map((option, index) => (
                            <button
                              key={index}
                              className="action-menu-participants-option"
                              onClick={(e) => {
                                e.stopPropagation();
                                option.action();
                                setActionMenuOpen(null); // Close menu after action
                              }}
                            >
                              {option.label}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {
                    actionMenuOptions.length == 0 && (
                      <div className="more-options">
                      <MdMoreVert
                        disabled = {true}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActionMenu(participant.id);
                        }}
                      />
                       </div>
                    )
                  }
                  </div>

                  <div className="applied-date">
                    Applied on {format(new Date(participant.created_at), 'MMM dd, yyyy')}
                  </div>


                </div>

                <div className="participant-header">
                  <div className="profile-pic">
                    {participant.first_name.charAt(0)}
                    {participant.last_name.charAt(0)}
                  </div>
                  <div className="participant-name">
                    {participant.first_name} {participant.last_name}
                  </div>
                  <div className='profile-id-section'>
                    ID: {participant.id}
                    </div>
                  {/* Conditionally render the "Qualified" label */}
                  {participant.qualified && <div className="qualified-label">QUALIFIED</div>}


                </div>

                <div className="profile-info-grid">
                  <div className="info-item">
                    <FaGenderless /> {participant.gender}
                  </div>
                  <div className="info-item">
                    <FaUniversity /> {participant.education_level}
                  </div>
                  <div className="info-item">
                    <FaDollarSign /> {participant.household_income}
                  </div>
                  <div className="info-item">
                    <FaBriefcase /> {participant.occupation}
                  </div>
                  <div className="info-item">
                    <FaBuilding /> {participant.company}
                  </div>
                  <div className="info-item">
                    <FaMapMarkerAlt />{' '}
                    {[participant.city, participant.state, participant.country]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    )}

      {selectedParticipant && (
        <div className={`side-sheet ${selectedParticipant ? 'open' : ''}`}>
          <div className="side-sheet-header">
            <h2>Screener Responses</h2>
            <button className="close-button" onClick={handleCloseSideSheet}>
              &times;
            </button>
          </div>
          <div className="screener-responses">
            {selectedParticipant.screener_responses.map((response, index) => (
              <div key={index} className="response-item">
                <strong>{response.question}</strong>
                <p>{response.response}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Participants;
