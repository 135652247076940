import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import './CampaignBuilder.css';
import { FaSave, FaEdit, FaTrashAlt, FaArrowLeft } from 'react-icons/fa'; 
import { useAuth0 } from '@auth0/auth0-react';
import { ThreeDots } from 'react-loader-spinner';
import mixpanel, { identify } from 'mixpanel-browser';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import ResearchTypeStep from './CampaignBuilderSteps/ResearchTypeStep';
import CreativeOptionsStep from './CampaignBuilderSteps/CreativeOptionsStep';
import ObjectiveStep from './CampaignBuilderSteps/ObjectiveStep';
import QuestionsStep from './CampaignBuilderSteps/QuestionsStep';
import WhoStep from './CampaignBuilderSteps/WhoStep';
import ReviewStep from './CampaignBuilderSteps/ReviewStep';
import TestStep from './CampaignBuilderSteps/TestStep';
import IdentifyStep from './CampaignBuilderSteps/IdentifyStep';
import RecruitmentCriteriaBottomSheetStep from './CampaignBuilderSteps/RecruitmentCriteriaBottomSheetStep'; 
import ScreenerQuestionsBottomSheetStep from './CampaignBuilderSteps/ScreenerBottomSheetStep';
import PostLiveStep from './CampaignBuilderSteps/PostLiveStep';
import * as ApiUtils from './ApiUtils';
import * as ConstantUtils from './ConstantUtils';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const CampaignBuilder = ({showPostLiveStep}) => {

  const [searchParams] = useSearchParams();
  const initialStepParam = searchParams.get('step');
  const initialStep = initialStepParam !== null ? parseInt(initialStepParam, 10) : null;
  const navigate = useNavigate();
  const location = useLocation();
  const [campaign, setCampaign] = useState(null);
  const [step, setStep] = useState(initialStep !== null ? initialStep : 0);

  const [selectedBlock, setSelectedBlock] = useState(null); 

  const [questions, setQuestions] = useState(campaign && Array.isArray(campaign.questions) ? campaign.questions.map((q, index) => ({
    id: `question-${index + 1}`,
    question: q.question,
    followup: q.followup
  })) : []);
  
  const handleBackClick = () => {
    navigate(-1); 
  };
  
  const [editIndex, setEditIndex] = useState(null); 

  const [loading, setLoading] = useState(false); 
  const [newQuestion, setNewQuestion] = useState('');
  const [audienceOption, setAudienceOption] = useState('own'); 
  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);

  const [researchObjective, setResearchObjective] = useState(campaign ? campaign.business_objective : '');
  const [conceptDescription, setConceptDescription] = useState(campaign ? campaign.context : '');
  const questionsGeneratedRef = useRef(false);
  const [selectedCreativeType, setSelectedCreativeType] = useState(null);
  const [selectedCreativeQuantity, setSelectedCreativeQuantity] = useState(null);
  const [selectedCreativeTestMethod, setSelectedCreativeTestMethod] = useState(null);
  
  const countryOptions = ConstantUtils.countryOptions;
  const stateOptions = ConstantUtils.stateOptions;
  const educationOptions = ConstantUtils.educationOptions;

  const occupationOptions = ConstantUtils.occupationOptions;
  const incomeRangeOptions = ConstantUtils.incomeRangeOptions;

  const [campaignName, setCampaignName] = useState(campaign ? campaign.name : '');
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [initialObjective, setInitialObjective] = useState('');
  const [initialQuestions, setInitialQuestions] = useState(campaign ? campaign.questions : []);
  const [testLink, setTestLink] = useState(campaign ? campaign.test_link : null);
  const [liveLink, setLiveLink] = useState(campaign ? campaign.live_link : null);
  const [initialContext, setInitialContext] = useState('');
  const [navigatedBack, setNavigatedBack] = useState(false);
  const researchObjectiveRef = useRef(null);
  const conceptDescriptionRef = useRef(null);
  const [identificationMethod, setIdentificationMethod] = useState('none');
  const [customFieldInputVisible, setCustomFieldInputVisible] = useState(false); 
  const [fullLoading, setFullLoading] = useState(false); 
  
  const [suggestedFieldsForLink, setSuggestedFieldsForLink] = useState(["name", "email", "user_id"]); 
  const [suggestedFieldsForAsk, setSuggestedFieldsForAsk] = useState(["Name", "Email", "User ID"]); 

  const [selectedFieldsForLink, setSelectedFieldsForLink] = useState([]); 
  const [selectedFieldsForAsk, setSelectedFieldsForAsk] = useState([]); 
  
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const criteriaOptions = ConstantUtils.criteriaOptions;
  const [availableCriteria, setAvailableCriteria] = useState(criteriaOptions); 
  const [savedRecruitmentCriteria, setSavedRecruitmentCriteria] = useState({
    countries: [],
    state: [],
    age: { min: null, max: null },
    education: [],
    occupation: [],
    incomeRange: [],
    gender: null,
    language: []
  });
  
  const [savedScreenerQuestions, setSavedScreenerQuestions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]); 
  const [selectedState, setSelectedState] = useState([]); 
  const [selectedEducation, setSelectedEducation] = useState([]); 
  const [selectedOccupation, setSelectedOccupation] = useState([]); 
  const [selectedIncomeRange, setSelectedIncomeRange] = useState([]); 
  
  const [selectedGender, setSelectedGender] = useState(null); 
  const [selectedLanguage, setSelectedLanguage] = useState([]); 
  const [screenerQuestions, setScreenerQuestions] = useState([]); 
  const [numberOfParticipants, setNumberOfParticipants] = useState(0);
  const [incentiveAmount, setIncentiveAmount] = useState(0.0);
  const [externalProjectTitle, setExternalProjectTitle] = useState('');
  const [externalProjectDescription, setExternalProjectDescription] = useState('');
  
  const [customField, setCustomField] = useState(""); 
  const { id } = useParams();
  const [campaignId, setCampaignId] = useState(id || null); 

  const [validationErrors, setValidationErrors] = useState({
    participants: "",
    incentive: "",
    country: "",
    screenerQuestions: "",
    externalProjectTitle:"",
    externalProjectDescription:""

  });
  
  const genderOptions = ConstantUtils.genderOptions;
  const languageOptions = ConstantUtils.languageOptions
  const multipleChoiceActionOptions = ConstantUtils.multipleChoiceActionOptions
  const singleChoiceActionOptions = ConstantUtils.singleChoiceActionOptions
  const questionTypeOptions = ConstantUtils.questionTypeOptions

  const [targetAudience, setTargetAudience] = useState(null);
  const [isCustomField, setIsCustomField] = useState({});
  const [showRecruitmentSheet, setShowRecruitmentSheet] = useState(false);
  const [showScreenerSheet, setShowScreenerSheet] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState([]);


  const steps = (audienceOption === 'own'
    ? ['Research Type',
      ...(selectedBlock === 3 ? ['Creative Type'] : ['Objective']),
      ...(selectedBlock !== 3 ? ['Concept'] : []),
      'Questions', 'Who', 'Review', 'Test', 'Identify']
    : ['Research Type',
      ...(selectedBlock === 3 ? ['Creative Type'] : ['Objective']),
      ...(selectedBlock !== 3 ? ['Concept'] : []),
      'Questions', 'Who', 'Review', 'Test']
  );
  
  


  
  
const addNewQuestion = (questionType) => {
  setScreenerQuestions([
    ...screenerQuestions,
    {
      id: `question-${screenerQuestions.length + 1}`,
      questionText: '',
      questionType: questionType,
      options: ['short', 'long'].includes(questionType) ? [] : [{ text: '', action: 'none' }],
      required: false,
      autoAccept: [],
      autoReject: [],
    },
  ]);
};

const handleAddOption = (questionId, optionIndex) => {
  setScreenerQuestions(prevQuestions =>
    prevQuestions.map(q =>
      q.id === questionId
        ? {
            ...q,
            options: [
              ...q.options.slice(0, optionIndex + 1),
              { text: '', action: 'none' },
              ...q.options.slice(optionIndex + 1),
            ],
          }
        : q
    )
  );
};

  
const handleRemoveOption = (questionId, optionIndex) => {
  setScreenerQuestions(prevQuestions =>
    prevQuestions.map(q => {
      if (q.id === questionId) {
        const updatedOptions = [...q.options];
        updatedOptions.splice(optionIndex, 1); // Remove the selected option

        return { ...q, options: updatedOptions };
      }
      return q;
    })
  );
};


  const handleToggleRequired = (questionId) => {
    const updatedQuestions = screenerQuestions.map((q) =>
      q.id === questionId ? { ...q, required: !q.required } : q
    );
    setScreenerQuestions(updatedQuestions);
  };

  

  const applyRecruitmentSettings = (campaign) => {
    if (campaign.audience_option === "recruit") {
      setAudienceOption("recruit");
  
      setNumberOfParticipants(campaign.target_audience.number_of_participants || 0);
      setIncentiveAmount(campaign.target_audience.incentive_amount || 0.0);
  
      setSelectedCountry(
        (campaign.target_audience.countries || []).map(countryValue => {
          return countryOptions.find(option => option.value === countryValue) || { value: countryValue, label: countryValue };
        })
      );
  
      setSelectedState(
        (campaign.target_audience.state || []).map(stateValue => {
          return stateOptions.find(option => option.value === stateValue) || { value: stateValue, label: stateValue };
        })
      );
  
      setSelectedEducation(
        (campaign.target_audience.education || []).map(educationValue => {
          return educationOptions.find(option => option.value === educationValue) || { value: educationValue, label: educationValue };
        })
      );
  
      setSelectedOccupation(
        (campaign.target_audience.occupation || []).map(occupationValue => {
          return occupationOptions.find(option => option.value === occupationValue) || { value: occupationValue, label: occupationValue };
        })
      );
  
      setSelectedIncomeRange(
        (campaign.target_audience.income_range || []).map(incomeValue => {
          return incomeRangeOptions.find(option => option.value === incomeValue) || { value: incomeValue, label: incomeValue };
        })
      );
      setExternalProjectTitle(campaign.target_audience.external_title || '');
      setExternalProjectDescription(campaign.target_audience.external_description || '');

  
      setMinAge(campaign.target_audience.age_min || '');
      setMaxAge(campaign.target_audience.age_max || '');
  
      setSelectedGender(
        campaign.target_audience.gender
          ? genderOptions.find(option => option.value === campaign.target_audience.gender) || { value: campaign.target_audience.gender, label: campaign.target_audience.gender }
          : null
      );
  
      setSelectedLanguage(
        (campaign.target_audience.languages || []).map(languageValue => {
          return languageOptions.find(option => option.value === languageValue) || { value: languageValue, label: languageValue };
        })
      );
  
      const recruitmentCriteria = {
        countries: (campaign.target_audience.countries || []).map(countryValue => {
          return countryOptions.find(option => option.value === countryValue)?.label || countryValue;
        }),
        state: (campaign.target_audience.state || []).map(stateValue => {
          return stateOptions.find(option => option.value === stateValue)?.label || stateValue;
        }),
        age: { min: campaign.target_audience.age_min, max: campaign.target_audience.age_max },
        education: (campaign.target_audience.education || []).map(educationValue => {
          return educationOptions.find(option => option.value === educationValue)?.label || educationValue;
        }),
        occupation: (campaign.target_audience.occupation || []).map(occupationValue => {
          return occupationOptions.find(option => option.value === occupationValue)?.label || occupationValue;
        }),
        incomeRange: (campaign.target_audience.income_range || []).map(incomeValue => {
          return incomeRangeOptions.find(option => option.value === incomeValue)?.label || incomeValue;
        }),
        gender: campaign.target_audience.gender
          ? genderOptions.find(option => option.value === campaign.target_audience.gender)?.label || campaign.target_audience.gender
          : null,
        language: (campaign.target_audience.languages || []).map(languageValue => {
          return languageOptions.find(option => option.value === languageValue)?.label || languageValue;
        }),
      };
  
      setSavedRecruitmentCriteria(recruitmentCriteria);
  
      let criteriaList = [];
  
      if ((campaign.target_audience.countries || []).length > 0) criteriaList.push('Country');
      if ((campaign.target_audience.state || []).length > 0) criteriaList.push('State');
      if (campaign.target_audience.age_min || campaign.target_audience.age_max) criteriaList.push('Age');
      if ((campaign.target_audience.education || []).length > 0) criteriaList.push('Education Level');
      if ((campaign.target_audience.occupation || []).length > 0) criteriaList.push('Occupation');
      if ((campaign.target_audience.income_range || []).length > 0) criteriaList.push('Household Income');
      if (campaign.target_audience.gender) criteriaList.push('Gender');
      if ((campaign.target_audience.languages || []).length > 0) criteriaList.push('Language');
  
      setSelectedCriteria(criteriaList);
      setAvailableCriteria(criteriaOptions.filter(option => !criteriaList.includes(option)));
  
      if (campaign.target_audience.screener_questions) {
        const screenerQuestionsData = campaign.target_audience.screener_questions.map((q, index) => ({
          id: `question-${index + 1}`,
          questionText: q.question_text,
          questionType: q.question_type,
          options: q.options || [],
          required: q.required || false,
        }));
        setScreenerQuestions(screenerQuestionsData);
        setSavedScreenerQuestions(screenerQuestionsData);
      }
    }
  };

  

  const hasCampaignChanged = () => {
    const currentObjective = researchObjective;
    const currentContext = conceptDescription;
    const currentQuestions = questions;

    const objectiveChanged = currentObjective !== initialObjective;
    const contextChanged = currentContext !== initialContext;
    const questionsChanged = JSON.stringify(currentQuestions) !== JSON.stringify(initialQuestions);

    return objectiveChanged || contextChanged || questionsChanged;
};

const handleCriteriaSelect = (option) => {
  if (!selectedCriteria.includes(option)) {
    setAvailableCriteria(availableCriteria.filter((item) => item !== option));
    setSelectedCriteria([...selectedCriteria, option]);
  }
};

const handleAddField = (field, isFromSuggested = false, optionType = 'link') => {
  
    setIsCustomField({
      ...isCustomField,
      [field]: !isFromSuggested, 
    });

    if (isFromSuggested) {
      if (optionType === 'link') {
        setSelectedFieldsForLink([...selectedFieldsForLink, field]);
        setSuggestedFieldsForLink(suggestedFieldsForLink.filter(suggestedField => suggestedField !== field));
      } else if (optionType === 'info') {
        setSelectedFieldsForAsk([...selectedFieldsForAsk, field]);
        setSuggestedFieldsForAsk(suggestedFieldsForAsk.filter(suggestedField => suggestedField !== field));
      }
    }
  
};


const handleRemoveField = (field, optionType = 'link') => { 

  if (!isCustomField[field]) {

    if (optionType === 'link') {
      setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));

      setSuggestedFieldsForLink([...suggestedFieldsForLink, field]);
    } else if (optionType === 'info') {

      setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
      setSuggestedFieldsForAsk([...suggestedFieldsForAsk, field]);
    }
  }
  else {
    if (optionType === 'link') {
      setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));
    } else if (optionType === 'info') {
      setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
    }

  }

  const updatedCustomFieldStatus = { ...isCustomField };
  delete updatedCustomFieldStatus[field];
  setIsCustomField(updatedCustomFieldStatus);
};


const handleCriteriaDeselect = (option) => {

  setSelectedCriteria(selectedCriteria.filter((item) => item !== option));

  setAvailableCriteria([...availableCriteria, option]);

  switch (option) {
    case 'Country':
      setSelectedCountry([]); 
      break;
    case 'State':
      setSelectedState([]); 
      break;
    case 'Age':
      setMinAge(''); 
      setMaxAge(''); 
      break;
    case 'Education Level':
      setSelectedEducation([]); 
      break;
    case 'Occupation':
      setSelectedOccupation([]); 
      break;
    case 'Household Income':
      setSelectedIncomeRange([]); 
      break;
    case 'Gender':
      setSelectedGender(null); 
      break;
    case 'Language':
      setSelectedLanguage([]); 
      break;
    default:
      break;
  }
};



const handleAddCustomField = (optionType) => {
  if (customField.trim()) {
    let fieldToAdd = customField.trim();

    if (optionType === "link") {
      fieldToAdd = fieldToAdd.toLowerCase().replace(/\s+/g, '_');
    }

    if (optionType === "link") {
      if (!selectedFieldsForLink.includes(fieldToAdd)) {
        setSelectedFieldsForLink([...selectedFieldsForLink, fieldToAdd]);
      }
    } else {
      if (!selectedFieldsForAsk.includes(fieldToAdd)) {
        setSelectedFieldsForAsk([...selectedFieldsForAsk, fieldToAdd]);
      }
    }

    setIsCustomField({
      ...isCustomField,
      [fieldToAdd]: true, 
    });

    setCustomField(""); 
  }
};

  const generateCampaignQuestions = async (instruction) => {
    try {
  
      const payload = instruction ? { instruction } : {};
      const questions = ApiUtils.generateCampaignQuestions({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims, instruction: payload})
  
      return questions;
    } catch (error) {
      console.error('Error generating questions:', error);
      throw error;
    }
  };
  

  const continueStep = async () => {

    setLoading(true); 
    setNavigatedBack(false);

    const isObjectiveStep = (step === 1 && selectedBlock !== 3) || (step === 2 && selectedBlock === 3);
    if (isObjectiveStep) {
      if (!validateResearchObjective(researchObjective)) {
        setLoading(false);
        return;
      }
    }

    if (audienceOption === "recruit" && !validateRecruitmentSection()) {
      setLoading(false); 
      return;
    }
    
    if (selectedBlock !=3 && step === 5) {
      try {
          const campaignChanged = hasCampaignChanged();  
          if (testLink && campaignChanged) {
            
            const updatedInterview = await ApiUtils.createOrUpdateInterview( {interviewId: testLink.id,  campaignId, getAccessTokenSilently, getIdTokenClaims, isTestInterview: true});
            setTestLink(updatedInterview);
          } else if (!testLink) {
            const newInterview = await ApiUtils.createOrUpdateInterview({interviewId: null, campaignId, getAccessTokenSilently, getIdTokenClaims,isTestInterview: true});
            setTestLink(newInterview.id);
          }
      } catch (error) {
          console.error('Error during interview API call:', error);
      }
  }
  
  if (selectedBlock !=3 && step === 6) {

    try {
        const campaignChanged = hasCampaignChanged();
        if (liveLink && campaignChanged) {
            
          const updatedLiveInterview = await ApiUtils.createOrUpdateInterview({interviewId: liveLink.id, campaignId, getAccessTokenSilently, getIdTokenClaims, isTestInterview: false});
          setLiveLink(updatedLiveInterview);  
        } else if (!liveLink) {            
          const newLiveInterview = await ApiUtils.createOrUpdateInterview({interviewId: null, campaignId, getAccessTokenSilently, getIdTokenClaims, isTestInterview: false});
          setLiveLink(newLiveInterview.id);
        }
    } catch (error) {
        console.error('Error during live interview API call:', error);
    }
}

    await saveCurrentStep();

    if (step < steps.length) {
      let nextStep = step + 1;

      if (nextStep === 2 && (selectedBlock === 1 || selectedBlock === 4)) {
          nextStep = 3;
      }


          if (selectedBlock !=3 && nextStep !== 3) {
            questionsGeneratedRef.current = false;
          }
      

      setStep(nextStep);
    }

    if (step == steps.length - 1) {
      handleBackClick();
    }

        setLoading(false); 
};

  const createOrUpdateCampaign = async () => {
    try {
      const campaignData = {
        name: campaignName,
        type: selectedBlock ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] : null,
        business_objective: document.getElementById('researchObjective')?.value || '',
        context: document.getElementById('conceptDescription')?.value || '',
        current_step: step,
        project_type: 'ai_moderated'
      };

      const data = await ApiUtils.createOrUpdateCampaign({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims, campaignData: campaignData});
      return data;
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
      throw error;
    }
  };

  const handleQuestionTextChange = (questionId, newText) => {
    setScreenerQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, questionText: newText } : q
      )
    );
  };


  const saveCurrentStep = async () => {
    let campaignData = {};

    switch (step) {
        case 0:
            const researchType = selectedBlock 
                ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] 
                : null;

            campaignData = {
                name: campaignName,
                type: researchType,
                current_step: step
            };

            if (campaignId) {
                await updateCampaign(campaignData);
            } else {
                campaignData = await createOrUpdateCampaign();
                // setCampaign(campaignData);
                setCampaignId(campaignData.id);
            }
            if (audienceOption != 'recruit') {
              setAudienceOption('own');
            }
            break;

        case 1:
          if( selectedBlock !=3) {
            campaignData = {
                business_objective: document.getElementById('researchObjective').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
          }
            break;

        case 2:
          if( selectedBlock !=3) {
            campaignData = {
                context: document.getElementById('conceptDescription').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
          }
            break;

        case 3:
          if( selectedBlock !=3) {
            campaignData = {
              questions: questions.map(q => ({
                  question: q.question,
                  followup: q.followup
              })),
              name: campaignName,
              current_step: step
          };

          await updateCampaign(campaignData);
        }
          break;

        
        case 4:
          if( selectedBlock !=3) {
          const targetData = audienceOption === 'recruit' ? {
            countries: selectedCountry.map(c => c.value),
            state: selectedState.map(s => s.value),
            education: selectedEducation.map(e => e.value),
            occupation: selectedOccupation.map(o => o.value),
            income_range: selectedIncomeRange.map(i => i.value),
            age_min: minAge || null,
            age_max: maxAge || null,
            gender: selectedGender ? selectedGender.value : null,
            languages: selectedLanguage.map(l => l.value),
            number_of_participants: numberOfParticipants,  
            incentive_amount: incentiveAmount,
            external_title: externalProjectTitle,
            external_description: externalProjectDescription,  
            screener_questions: screenerQuestions.map(question => ({
              question_text: question.questionText,
              question_type: question.questionType,
              required: question.required,
              options: question.questionType === 'multiple' || question.questionType === 'single'
                ? question.options.map(option => ({
                    text: option.text,
                    action: option.action
                  }))
                : ['short', 'long'].includes(question.questionType)
                  ? [{ text: question.answerText || '' }]  
                  : []
            })),
            
          } : null;

         
    
          const hasRecruitmentDataChanged = JSON.stringify(targetAudience) !== JSON.stringify(targetData);

          
    
          if (hasRecruitmentDataChanged) {
            campaignData = {
              audience_option: audienceOption,
              target_audience: targetData,
              name: campaignName,
              current_step: step,
            };

           
            await updateCampaign(campaignData);
          }
        }
          break;

          case 6:
            if (audienceOption == 'recruit') {
              let identificationFields = {};
      
              if (identificationMethod === 'link') {
                identificationFields = { fields: selectedFieldsForLink };
              } else if (identificationMethod === 'ask') {
                identificationFields = { fields: selectedFieldsForAsk };
              }
        
              campaignData = {
                identification_method: identificationMethod,
                identification_fields: identificationFields,
                current_step: step,
                name: campaignName,
              };
              await updateCampaign(campaignData);
              break;
            }        
  

        case 7:
          if (audienceOption == 'own') {
            let identificationFields = {};
    
            if (identificationMethod === 'link') {
              identificationFields = { fields: selectedFieldsForLink };
            } else if (identificationMethod === 'ask') {
              identificationFields = { fields: selectedFieldsForAsk };
            }
      
            campaignData = {
              identification_method: identificationMethod,
              identification_fields: identificationFields,
              current_step: step,
              name: campaignName,
            };
            await updateCampaign(campaignData);
            break;
          }        

        default:
            campaignData = {
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;
    }
};

const checkAndGenerateQuestions = () => {
  const objectiveChanged = researchObjective !== initialObjective;
  const contextChanged = conceptDescription !== initialContext;


  if (objectiveChanged || contextChanged) {
    setIsGeneratingQuestions(true);
    
    // Return a promise that resolves immediately
    return new Promise((resolve) => {
      // Use setTimeout to move the operation to the next event loop tick
      setTimeout(async () => {
        try {
          const generatedQuestions = await generateCampaignQuestions();
          const newQuestions = generatedQuestions.map((q, index) => ({
            id: `question-${index + 1}`,
            question: q.question,
            followup: q.followup
          }));
          
          // Update questions in the state
          setQuestions(newQuestions);

          // Update campaign data
          await updateCampaign({
            questions: newQuestions,
            business_objective: researchObjective,
            context: conceptDescription
          });

          // Update initial values
          setInitialObjective(researchObjective);
          setInitialContext(conceptDescription);
        } catch (error) {
          console.error("Error generating questions:", error);
        } finally {
          setIsGeneratingQuestions(false);
        }
      }, 0);

      // Resolve the promise immediately
      resolve();
    });
  } else {
    // If no changes, return a resolved promise
    return Promise.resolve();
  }
};


const updateCampaign = async (campaignData) => {
    try {
      const updatedCampaign = await ApiUtils.updateCampaign({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims, campaignData: campaignData});
      return updatedCampaign;
    } catch (error) {
        console.error('Error updating campaign:', error);
        throw error;
    }
};

const handleSaveRecruitmentCriteria = () => {
  // Collect the selected recruitment criteria
  const recruitmentCriteria = {
    countries: selectedCountry.map(c => c.label),
    state: selectedState.map(s => s.label),
    age: { min: minAge, max: maxAge },
    education: selectedEducation.map(e => e.label),
    occupation: selectedOccupation.map(o => o.label),
    incomeRange: selectedIncomeRange.map(i => i.label),
    gender: selectedGender?.label,
    language: selectedLanguage?.map(l => l.label),
  };

  // Save the recruitment criteria and close the sheet
  setSavedRecruitmentCriteria(recruitmentCriteria);
  setShowRecruitmentSheet(false);
};

const handleSaveScreenerQuestions = () => {
  // Collect the selected screener questions
  const screenerQuestionList = [...screenerQuestions]; // Assuming you have screener questions logic elsewhere
  setSavedScreenerQuestions(screenerQuestionList);
  setShowScreenerSheet(false);
};

const handleEditRecruitmentCriteria = () => {
  setShowRecruitmentSheet(true);
};

// Function to edit screener questions (re-open the screener sheet)
const handleEditScreenerQuestions = () => {
  setShowScreenerSheet(true);
};

const handleQuestionChange = (index, field, value) => {
  const updatedQuestions = questions.map((q, i) =>
    i === index ? { ...q, [field]: value } : q
  );
  setQuestions(updatedQuestions);
};

    const handleOnDragEnd = (result) => {
      
      if (!result.destination) {
        
        return;
      }

      const items = Array.from(questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      
      setQuestions([...items]);
    };
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const validateRecruitmentSection = () => {
    const errors = {
      participants: "",
      incentive: "",
      country: "",
      screenerQuestions: ""
    };
  
    if (numberOfParticipants <= 0) {
      errors.participants = "Number of participants must be greater than zero.";
    }
  
    if (incentiveAmount < 5) {
      errors.incentive = "Incentive amount must be at least $5.";
    }
  
    if (!selectedCountry || selectedCountry.length === 0) {
      errors.country = "Please select at least one country.";
    }
  
    if (!screenerQuestions || screenerQuestions.length < 2) {
      errors.screenerQuestions = "Please add at least two screener questions.";
    }
  
    setValidationErrors(errors);
  
    return Object.values(errors).every((error) => error === "");
  };



const handleAddQuestion = () => {
  if (newQuestion.trim() !== '') {
    const newQuestionObj = {
      id: `question-${questions.length + 1}`,
      question: newQuestion,
      followup: ''
    };
    setQuestions([...questions, newQuestionObj]);
    setNewQuestion('');
  }
};

const handleDeleteQuestion = (index) => {
  const updatedQuestions = questions.filter((_, i) => i !== index);
  setQuestions(updatedQuestions);
};

const handleEditQuestion = (index) => {
  setEditIndex(index);
};

const handleQuestionTypeChange = (questionId, newQuestionType) => {
  setScreenerQuestions(prevQuestions => 
    prevQuestions.map(q => {
      if (q.id === questionId) {
        const minOptions = newQuestionType === 'multiple' ? 2 : 2;
        let updatedOptions = q.options;

        // Ensure there are at least minOptions for the selected question type
        if (updatedOptions.length < minOptions) {
          const optionsToAdd = minOptions - updatedOptions.length;
          for (let i = 0; i < optionsToAdd; i++) {
            updatedOptions.push({ text: '', action: '' });
          }
        }

        return { ...q, questionType: newQuestionType, options: updatedOptions };
      }
      return q;
    })
  );
};


const handleStepChange = (newStep, navigatedBack) => {

  if (navigatedBack) {
    

    if ( step === 3) {
      
      if (selectedBlock === 1 || selectedBlock === 4) {
        setStep(1); 
      } else {
        setStep(newStep);
      }
    }
  else {
      setStep(newStep);
    }
  }
  else {
    setStep(newStep);
  }
};

const handleSave = () => {
  saveCurrentStep();
  checkAndGenerateQuestions();
};

const hasNonEmptyCriteria = (criteria) => {
  if (!criteria) return false;
  const { countries, state, age, education, occupation, incomeRange, gender, language } = criteria;
  return (
    (countries && countries.length > 0) ||
    (state && state.length > 0) ||
    ((age && (age.min || age.max))) ||
    (education && education.length > 0) ||
    (occupation && occupation.length > 0) ||
    (incomeRange && incomeRange.length > 0) ||
    (gender && gender !== '') ||
    (language && language.length > 0)
  );
};

  const handleOptionChange = (questionId, optionIndex, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: q.options.map((option, idx) =>
                idx === optionIndex ? { ...option, text: newText } : option
              ),
            }
          : q
      )
    );
  };

  const handleAnswerTextChange = (questionId, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? { ...q, answerText: newText }
          : q
      )
    );
  };
  

  const handleOptionActionChange = (questionId, optionIndex, newAction) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: q.options.map((option, idx) =>
                idx === optionIndex ? { ...option, action: newAction } : option
              ),
            }
          : q
      )
    );
  };

  const handleScreenerDragEnd = (result) => {
    if (!result.destination) return;
  
    const items = Array.from(screenerQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setScreenerQuestions(items);
  };
  
  const handleDeleteScreenerQuestion = (questionId) => {
    setScreenerQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== questionId)
    );
  };

  const validateParticipants = (value) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      participants: value > 0 ? "" : "Number of participants must be greater than zero."
    }));
  };

  const validateExternalTitle = (value) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      externalProjectTitle: value.trim() !== '' ? "" : "External project title cannot be empty."
    }));
  };

  const validateExternalDescription = (value) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      externalProjectDescription: value.trim() !== '' ? "" : "External project Description cannot be empty."
    }));
  };
  
  
  const validateIncentive = (value) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      incentive: value >= 5 ? "" : "Incentive amount must be at least $5."
    }));
  };
  
  const validateCountry = (selectedCountries) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      country: selectedCountries && selectedCountries.length > 0 ? "" : "Please select at least one country."
    }));
  };
  
  const validateScreenerQuestions = (questions) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      screenerQuestions: questions.length >= 2 ? "" : "Please add at least two screener questions."
    }));
  };


const handleParticipantsChange = (e) => {
  const value = e.target.value;
  setNumberOfParticipants(value);
  validateParticipants(value); 
};

const handleExternalTitleChange = (e) => {
  const value = e.target.value;
  setExternalProjectTitle(value);
  validateExternalTitle(value);  
};

const handleExternalDescriptionChange = (e) => {
  const value = e.target.value;
  setExternalProjectDescription(value);
  validateExternalDescription(value);  
};


const handleIncentiveChange = (e) => {
  const value = e.target.value;
  setIncentiveAmount(value);
  validateIncentive(value);  
};


const handleScreenerQuestionUpdate = (updatedQuestions) => {
  setScreenerQuestions(updatedQuestions);
  validateScreenerQuestions(updatedQuestions); 
};

const validateResearchObjective = (objective) => {
  const errors = {};

  if (!objective.trim()) {
    errors.researchObjective = "Please enter a business objective to continue.";
  } else if (objective.trim().split(/\s+/).length < 5) { // Check for at least 5 words
    errors.researchObjective = "Please provide more details about your research objective. This will help us craft an effective discussion guide tailored to your research needs.";
  }


  setValidationErrors((prevErrors) => ({
    ...prevErrors,
    ...errors,
  }));

  return Object.keys(errors).length === 0;
};



useEffect(() => {
  if (showScreenerSheet && screenerQuestions.length === 0) {
    addNewQuestion('multiple');
  }
}, [showScreenerSheet]);


useEffect(() => {
  
  const fetchCampaignData = async () => {
    if (campaignId) {
      setFullLoading(true); 
       
      try {
        const fetchedCampaign = await ApiUtils.fetchCampaignData({campaignId:campaignId, getAccessTokenSilently, getIdTokenClaims});
        // console.log("campaign data on load :", fetchedCampaign);
        setCampaign(fetchedCampaign);

      } catch (error) {
        console.error('Error fetching campaign data:', error);
      } finally {
        setFullLoading(false); 
      }
    }
    else {
      setStep(0);
      setCampaignName('');
      setSelectedBlock(null);
      setQuestions([]);
      setIdentificationMethod('no_identify'); 
      setSelectedFieldsForLink([]); 
      setSelectedFieldsForAsk([]); 
  }
  };

  fetchCampaignData();
}, [id]);


useEffect(() => {
  mixpanel.track('CampaignBuilderViewed');
}, []);

useEffect(() => {
  if (campaign) {
    if (step === 1 && researchObjectiveRef.current) {
      setInitialObjective(researchObjectiveRef.current.value || '');
    }
    if (step === 2 && conceptDescriptionRef.current) {
      setInitialContext(conceptDescriptionRef.current.value || '');
    }
  }
}, [campaign, step]);



useEffect(() => {
  if (audienceOption === 'recruit') {
    validateCountry(selectedCountry);
  }
}, [selectedCountry]);

useEffect(() => {
  if (audienceOption === 'recruit') {
    validateScreenerQuestions(screenerQuestions);
  }
}, [screenerQuestions]);


useEffect(() => {
  if (step === 3 && !navigatedBack) {
    checkAndGenerateQuestions();
  }
}, [step, navigatedBack]);

useEffect(() => {
  if (campaign) {
    setStep(initialStep !== null ? initialStep : (campaign.current_step || 0));
    setCampaignName(campaign.name || null);
    setAudienceOption(campaign.audience_option);
    setTargetAudience(campaign.target_audience);
    setResearchObjective(campaign.business_objective || '');
    setConceptDescription(campaign.context || '');
    setTestLink(campaign.test_link);
    setInitialObjective(campaign.business_objective || '');
    setTestLink(campaign.test_link);
    setInitialContext(campaign.context || '');
    
    const typeToBlockMap = {
      'consumer_research': 1,
      'ad_testing': 2,
      'concept_testing': 3,
      'consumer_feedback': 4
    };
    setSelectedBlock(typeToBlockMap[campaign.type] || null);
    
    if (campaign?.questions) {
      setQuestions(campaign.questions.map((q, index) => ({
        id: `question-${index + 1}`,
        question: q.question,
        followup: q.followup
      })));
    }

    applyRecruitmentSettings(campaign);

    if (campaign.identification_method) {
      setIdentificationMethod(campaign.identification_method);

      if (campaign.identification_method === 'link') {
        setSelectedFieldsForLink(campaign.identification_fields?.fields || []);
      } else if (campaign.identification_method === 'ask') {
        setSelectedFieldsForAsk(campaign.identification_fields?.fields || []);
      }
    }

  } else {
    setStep(initialStep !== null ? initialStep : 0);
    setCampaignName(null);
    setSelectedBlock(null);
    setQuestions([]);
    setIdentificationMethod('no_identify'); 
    setSelectedFieldsForLink([]); 
    setSelectedFieldsForAsk([]); 
}
}, [campaign]);


  
  
if (fullLoading) {
  return (
    <div className="loading-indicator-container">
      <div className="loading-indicator">
      </div>
    </div>
  );
}

  return (
    
    <div className="campaign-builder">
          {showPostLiveStep ? (
          <PostLiveStep 
          handleBackClick={handleBackClick}
          campaign={campaign} />
        ) : (

          <><div className="builder-header">
            <button className="back-button-campaign-builder" onClick={handleBackClick}>
              &#8592;
            </button>

            <input
              type="text"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Add project name"
              className="campaign-name-input" />

          </div><div className="campaign-builder-container">


              <RecruitmentCriteriaBottomSheetStep
                showRecruitmentSheet={showRecruitmentSheet}
                setShowRecruitmentSheet={setShowRecruitmentSheet}
                selectedCriteria={selectedCriteria}
                setSelectedCriteria={setSelectedCriteria}
                availableCriteria={availableCriteria}
                setAvailableCriteria={setAvailableCriteria}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                selectedEducation={selectedEducation}
                setSelectedEducation={setSelectedEducation}
                selectedOccupation={selectedOccupation}
                setSelectedOccupation={setSelectedOccupation}
                selectedIncomeRange={selectedIncomeRange}
                setSelectedIncomeRange={setSelectedIncomeRange}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                minAge={minAge}
                setMinAge={setMinAge}
                maxAge={maxAge}
                setMaxAge={setMaxAge}
                handleCriteriaSelect={handleCriteriaSelect}
                handleCriteriaDeselect={handleCriteriaDeselect}
                handleSaveRecruitmentCriteria={handleSaveRecruitmentCriteria}
                countryOptions={countryOptions}
                stateOptions={stateOptions}
                educationOptions={educationOptions}
                occupationOptions={occupationOptions}
                incomeRangeOptions={incomeRangeOptions}
                genderOptions={genderOptions}
                languageOptions={languageOptions} />

              <ScreenerQuestionsBottomSheetStep
                showScreenerSheet={showScreenerSheet}
                setShowScreenerSheet={setShowScreenerSheet}
                screenerQuestions={screenerQuestions}
                setScreenerQuestions={setScreenerQuestions}
                handleSaveScreenerQuestions={handleSaveScreenerQuestions}
                addNewQuestion={addNewQuestion}
                handleQuestionTextChange={handleQuestionTextChange}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleDeleteScreenerQuestion={handleDeleteScreenerQuestion}
                handleAddOption={handleAddOption}
                handleRemoveOption={handleRemoveOption}
                handleOptionChange={handleOptionChange}
                handleOptionActionChange={handleOptionActionChange}
                handleAnswerTextChange={handleAnswerTextChange}
                handleToggleRequired={handleToggleRequired}
                questionTypeOptions={questionTypeOptions}
                multipleChoiceActionOptions={multipleChoiceActionOptions}
                singleChoiceActionOptions={singleChoiceActionOptions}
                handleScreenerDragEnd={handleScreenerDragEnd}
                handleScreenerQuestionUpdate={handleScreenerQuestionUpdate} />

              <div className="builder-container" onClick={(e) => e.stopPropagation()}>
                <div className="progress-container">
                  <div className="progress-indicator">
                    {steps.filter((_, index) => !(selectedBlock === 1 || selectedBlock === 4) || index !== 2
                    ).map((stepName, index) => {
                      // Adjust the step number for comparison
                      const adjustedStep = (selectedBlock === 1 || selectedBlock === 4) && index > 1 ? index + 1 : index;
                      const visibleStep = (selectedBlock === 1 || selectedBlock === 4) ?
                        [0, 1, 3, 4, 5, 6, 7][index] :
                        index;

                      return (
                        <span
                          key={index}
                          className={`step ${visibleStep === step ? 'current-step' : ''} ${visibleStep <= step ? 'completed' : 'incomplete'}`}
                          onClick={visibleStep <= step ? () => handleStepChange(visibleStep, visibleStep < step) : null}

                        >
                          {stepName}
                          {index < (steps.length - (selectedBlock === 1 || selectedBlock === 4 ? 2 : 1)) && (
                            <span className={`separator ${visibleStep <= step ? 'completed' : 'incomplete'}`}> &gt; </span>
                          )}
                        </span>
                      );
                    })}
                  </div>


                  <div className="button-container">
                    {step > 0 && (
                      <button className="button-container-back-button" onClick={() => handleStepChange((step - 1), true)}>
                        <FaArrowLeft /> Back
                      </button>

                    )}
                    <button className="save-button" onClick={handleSave}>
                      <FaSave /> Save Campaign
                    </button>
                    <button
                      className="continue-button"
                      onClick={continueStep}
                      disabled={loading}
                    >
                      {loading ? (
                        <ThreeDots color="#fff" height={10} width={24} />
                      ) : (
                        step === steps.length - 1 ? "Submit" : "Continue"
                      )}
                    </button>

                  </div>
                </div>

                <div className="builder-content">
                  {step === 0 && (
                    <ResearchTypeStep
                      selectedBlock={selectedBlock}
                      onSelectBlock={setSelectedBlock} />
                  )}

                  {step === 1 && selectedBlock === 3 && (
                    <CreativeOptionsStep
                      selectedCreativeType={selectedCreativeType}
                      setSelectedCreativeType={setSelectedCreativeType}
                      selectedCreativeQuantity={selectedCreativeQuantity}
                      setSelectedCreativeQuantity={setSelectedCreativeQuantity}
                      selectedCreativeTestMethod={selectedCreativeTestMethod}
                      setSelectedCreativeTestMethod={setSelectedCreativeTestMethod} />
                  )}

                  {(selectedBlock === 3 && step === 2) || (step === 1 && selectedBlock !== 3) && (
                    <ObjectiveStep
                      researchObjective={researchObjective}
                      setResearchObjective={setResearchObjective}
                      validationError={validationErrors.researchObjective}
                      
                      />
                  )}

                  {(selectedBlock === 3 && step === 4) || (step === 3 && selectedBlock !== 3) && (
                    <QuestionsStep
                      questions={questions}
                      setQuestions={setQuestions}
                      newQuestion={newQuestion}
                      setNewQuestion={setNewQuestion}
                      handleAddQuestion={handleAddQuestion}
                      editIndex={editIndex}
                      setEditIndex={setEditIndex}
                      handleQuestionChange={handleQuestionChange}
                      handleDeleteQuestion={handleDeleteQuestion}
                      isGeneratingQuestions={isGeneratingQuestions}
                      handleOnDragEnd={handleOnDragEnd} />
                  )}
                  {((selectedBlock === 3 && step === 5) || (step === 4 && selectedBlock !== 3)) && (
                    <WhoStep
                      audienceOption={audienceOption}
                      setAudienceOption={setAudienceOption}
                      numberOfParticipants={numberOfParticipants}
                      incentiveAmount={incentiveAmount}
                      setShowRecruitmentSheet={setShowRecruitmentSheet}
                      setShowScreenerSheet={setShowScreenerSheet}
                      handleEditRecruitmentCriteria={handleEditRecruitmentCriteria}
                      handleEditScreenerQuestions={handleEditScreenerQuestions}
                      validationErrors={validationErrors}
                      savedRecruitmentCriteria={savedRecruitmentCriteria}
                      savedScreenerQuestions={savedScreenerQuestions}
                      hasNonEmptyCriteria={hasNonEmptyCriteria}
                      externalProjectTitle={externalProjectTitle}
                      externalProjectDescription={externalProjectDescription}
                      handleParticipantsChange={handleParticipantsChange}
                      handleIncentiveChange={handleIncentiveChange}
                      handleExternalTitleChange={handleExternalTitleChange}
                      handleExternalDescriptionChange={handleExternalDescriptionChange} />
                  )}


                  {((selectedBlock === 3 && step === 6) || (step === 5 && selectedBlock !== 3)) && (
                    <ReviewStep
                      selectedBlock={selectedBlock}
                      researchObjective={researchObjective}
                      conceptDescription={conceptDescription}
                      questions={questions}
                      audienceOption={audienceOption}
                      numberOfParticipants={numberOfParticipants}
                      incentiveAmount={incentiveAmount}
                      selectedCountry={selectedCountry}
                      selectedState={selectedState}
                      selectedEducation={selectedEducation}
                      selectedOccupation={selectedOccupation}
                      selectedLanguage={selectedLanguage}
                      selectedIncomeRange={selectedIncomeRange}
                      selectedGender={selectedGender}
                      minAge={minAge}
                      maxAge={maxAge}
                      savedScreenerQuestions={savedScreenerQuestions}
                      externalProjectTitle={externalProjectTitle}
                      externalProjectDescription={externalProjectDescription}
                      hasNonEmptyCriteria={hasNonEmptyCriteria}
                      campaignName={campaignName}
                      setCampaignName={setCampaignName} />
                  )}

                  {((selectedBlock === 3 && step === 7) || (step === 6 && selectedBlock !== 3)) && (
                    <TestStep
                      testLink={testLink} />
                  )}

                  {step === steps.length - 1 && audienceOption === 'own' && (
                    <IdentifyStep
                      identificationMethod={identificationMethod}
                      setIdentificationMethod={setIdentificationMethod}
                      selectedFieldsForLink={selectedFieldsForLink}
                      setSelectedFieldsForLink={setSelectedFieldsForLink}
                      selectedFieldsForAsk={selectedFieldsForAsk}
                      setSelectedFieldsForAsk={setSelectedFieldsForAsk}
                      suggestedFieldsForLink={suggestedFieldsForLink}
                      setSuggestedFieldsForLink={setSuggestedFieldsForLink}
                      suggestedFieldsForAsk={suggestedFieldsForAsk}
                      setSuggestedFieldsForAsk={setSuggestedFieldsForAsk}
                      customField={customField}
                      setCustomField={setCustomField}
                      handleAddField={handleAddField}
                      handleRemoveField={handleRemoveField}
                      handleAddCustomField={handleAddCustomField}
                      isCustomField={isCustomField}
                      setIsCustomField={setIsCustomField} />
                  )}
                </div>
              </div>
            </div></>
        )}
        </div>
      
  );
};

export default CampaignBuilder;
