// CreativeOptionsStep.js

import React from 'react';
import { FaImage, FaVideo, FaArrowRight, FaColumns } from 'react-icons/fa';

const CreativeOptionsStep = ({
  selectedCreativeType,
  setSelectedCreativeType,
  selectedCreativeQuantity,
  setSelectedCreativeQuantity,
  selectedCreativeTestMethod,
  setSelectedCreativeTestMethod,
}) => {
  return (
    <div className="creative-options-step">
      <div className="step-label">Choose Creative Options</div>

      {/* First Question */}
      <div className="question">
        <div className="question-text">What is the type of creative you want to test?</div>
        <div className="options-row">
          <div
            className={`option-box ${selectedCreativeType === 'Image' ? 'selected' : ''}`}
            onClick={() => setSelectedCreativeType('Image')}
          >
            <FaImage className="option-icon" />
            <span className="option-text">Image</span>
          </div>
          <div
            className={`option-box ${selectedCreativeType === 'Video' ? 'selected' : ''}`}
            onClick={() => setSelectedCreativeType('Video')}
          >
            <FaVideo className="option-icon" />
            <span className="option-text">Video</span>
          </div>
        </div>
      </div>

      {/* Second Question */}
      <div className="question">
        <div className="question-text">How many creatives you want to test per participant?</div>
        <div className="options-row">
          {['Any 1', 'All'].map((option) => (
            <div
              key={option}
              className={`option-box ${selectedCreativeQuantity === option ? 'selected' : ''}`}
              onClick={() => setSelectedCreativeQuantity(option)}
            >
              <span className="option-text">{option}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Third Question */}
      <div className="question">
        <div className="question-text">How do you want to test the creatives?</div>
        <div className="options-row">
          <div
            className={`option-box ${selectedCreativeTestMethod === 'Sequentially' ? 'selected' : ''}`}
            onClick={() => setSelectedCreativeTestMethod('Sequentially')}
          >
            <div className="option-main">Sequentially</div>
            <div className="option-sub">
              <FaArrowRight />
            </div>
          </div>
          <div
            className={`option-box ${selectedCreativeTestMethod === 'Side by Side' ? 'selected' : ''}`}
            onClick={() => setSelectedCreativeTestMethod('Side by Side')}
          >
            <div className="option-main">Side by Side</div>
            <div className="option-sub">
              <FaColumns />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeOptionsStep;
